import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthRequest } from '../model/auth-request';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authUrl = environment.apiUrl + '/public/auth/token';

  constructor(private http: HttpClient) {
  }

  login(username: string, password: string) {
    var authRequest = new AuthRequest();
    authRequest.username = username;
    authRequest.password = password;
    return this.http.post(this.authUrl, authRequest);
  }

}
