import { KeyValue } from './key-value';
import { ChannelType } from './channel-type';

export class PaymentQueryRequest {


    channel: ChannelType;
    params: KeyValue[];

}
