<app-loading [isLoading]="isLoading" [message]="loadingMsg"></app-loading>
<div class="rectangle-first">
  <div
    class="container d-flex justify-content-between align-items-center rectangle-first"
  >
    <div>
      <div class="row align-items-center">
        <img
          src="assets/img/ouno.png"
          style="height: 36px; width: auto; margin-left: 16px;"
        />
        <div style="margin-left: 9px; margin-bottom: 0; font-size: 18px;"></div>
      </div>
    </div>
    <div></div>
  </div>
</div>

<div class="rectangle-body">
  <div class="container">
    <div class="card-body">
      <button
        [disabled]="isLoading"
        class="btn btn-primary"
        (click)="redirectToPaymentEshop()"
      >
        Eshop ödeme sayfasına git
      </button>

      <hr />

      <button
        [disabled]="isLoading"
        class="btn btn-primary"
        (click)="redirectToPaymentB2b()"
      >
        B2B ödeme sayfasına git
      </button>

    </div>
  </div>
</div>