import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Payment } from '../model/payment';
import { PaymentOrderIdRequest } from '../model/payment-order-id-request';
import { B2bPayment } from '../model/b2b-payment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  url = environment.apiUrl + "/order";

  constructor(private http: HttpClient) { }

  savePaymentMapping(payment: Payment) {
    return this.http.post(this.url + "/payment/paymentMapping", payment);
  }

  getPaymentByOrderId(request: PaymentOrderIdRequest) {
    return this.http.post(this.url + "/payment/order", request);
  }

  // b2b
  getB2bPaymentByOrderId(request: PaymentOrderIdRequest) {
    return this.http.post(this.url + "/payment/b2b/order", request);
  }

}
