import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { B2bPaymentComponent } from './component/b2b-payment/b2b-payment.component';
import { HomeComponent } from './component/home/home.component';
import { LoadingComponent } from './component/loading/loading.component';
import { PaymentResultComponent } from './component/payment-result/payment-result.component';
import { PaymentComponent } from './component/payment/payment.component';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { BoldMoneyPipe } from './util/bold-money.pipe';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { VodafonePaymentTestComponent } from './component/vodafone-payment-test/vodafone-payment-test.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PaymentComponent,
    B2bPaymentComponent,
    PaymentResultComponent,
    LoadingComponent,
    BoldMoneyPipe,
    PageNotFoundComponent,
    VodafonePaymentTestComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    InputMaskModule,
    InputTextModule,
    BrowserAnimationsModule,
    MessageModule,
    MessagesModule,
    ToastModule,
    DialogModule,
    DropdownModule,
    KeyFilterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
