import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CanActivateService implements CanActivate {
  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return Observable.create((observer: Observer<any>) => {
      if (environment.production) {
        if (route.url && route.url.length > 0) {
          var path0 = route.url[0].path;
          // console.log(path0);
          if (path0 === 'payment' || path0 === 'b2b-payment') {
            observer.next(true);
          } else {
            observer.next(false);
            this.router.navigate(["404"]);
          }
        } else {
          observer.next(false);
          this.router.navigate(["404"]);
        }
      } else {
        observer.next(true);
      }
    });
  }
}