import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.css']
})
export class PaymentResultComponent implements OnInit {

  status: string;
  message: string;

  constructor(private route: ActivatedRoute, private router: Router, private location: Location) {
  }

  ngOnInit() {
    if (this.route.snapshot.queryParams && this.route.snapshot.queryParams.status) {
      this.status = this.route.snapshot.queryParams.status;
    }
  }

  success(): boolean {
    return this.status === 'PAID';
  }

  retry() {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/'])
    }
  }

}
