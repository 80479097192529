<div class="rectangle-first">
  <div
    class="container d-flex justify-content-between align-items-center rectangle-first"
  >
    <div>
      <div class="row align-items-center">
        <img
          src="assets/img/ouno.png"
          style="height: 36px; width: auto; margin-left: 16px;"
        />
        <div style="margin-left: 9px; margin-bottom: 0; font-size: 18px;"></div>
      </div>
    </div>
    <div></div>
  </div>
</div>

<div class="container">
  <div class="card-body-error text-center" style="position: relative;">
    <span
      style="
        color: white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-20%, -70%);
      "
    >
      <img
        src="assets/img/alert-triangle.svg"
        style="width: 42px; height: 42px;"
      />
    </span>
    <div>
      Sayfa bulunamadı.
    </div>
  </div>
</div>
