import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'boldMoney' })
export class BoldMoneyPipe implements PipeTransform {
    transform(text: string): string {
        return this.makeBoldOnMoney(text);
    }

    makeBoldOnMoney(text: string): string {
        var result = "";
        var findCurrency = true;
        for (var i = 0; i < text.length; i++) {
            var item = text[i];
            if (findCurrency) {
                if (item === "₺") {
                    item = "<b>" + item;
                    findCurrency = false;
                }
            } else {
                if (item === " ") {
                    item = "</b>" + item;
                    findCurrency = true;
                }
            }
            result += item;
        }

        if (result.length == 0) {
            result = text;
        }
        return result;
    }

}