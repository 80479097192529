import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PaymentProcessRequest } from '../model/payment-process-request';
import { PaymentSetsRequest } from '../model/payment-sets-request';
import { PaymentOrderIdRequest } from '../model/payment-order-id-request';
import { PaymentQueryRequest } from '../model/payment-query-request';
import { MasterpassTokenRequest } from '../model/master-pass-token-request';
import {MasterPassPurchaseRequest} from "../model/master-pass-purchase-request";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  url = environment.apiUrl + "/payment";

  constructor(private http: HttpClient) { }

  paymentQuery(request: PaymentQueryRequest) {
    return this.http.post(this.url + "/query/", request);
  }

  getPaymentSets(request: PaymentSetsRequest) {
    return this.http.post(this.url + "/sets/", request);
  }

  processPayment(paymentProcessRequest: PaymentProcessRequest) {
    return this.http.post(this.url + "/process", paymentProcessRequest);
  }

  clearBeforeRetry(request: PaymentOrderIdRequest) {
    return this.http.post(this.url + "/clearBeforeRetry", request);
  }

  masterPassPurchase(request: MasterPassPurchaseRequest) {
    return this.http.post(this.url + "/masterPass/purchase", request);
  }

  masterPassToken(request: MasterpassTokenRequest) {
    return this.http.post(this.url + "/masterPass/token", request);
  }

}
