import { Injectable } from '@angular/core';
import { LogService } from './log.service';

declare var JSEncrypt: any;

@Injectable({
  providedIn: 'root'
})
export class EncriptionService {

  private privatekey = "MIIBVgIBADANBgkqhkiG9w0BAQEFAASCAUAwggE8AgEAAkEA3ECU/9vGg7oMH3wldiaB/WjjtyqD" +
    "JCfwJCpVFj2CWGOXIUWWIdEn1vO65zPUacwHE7pB8APB/qENlZ4WJDe2mQIDAQABAkEAvWyduN8f" +
    "gDMGMh3nahH8QiFvlxxs5S+kDLaLrzD/ZVvI1ZeYIcaryAprEvVd0yPNCq2b7fTUyMU+9P1JBy6q" +
    "8QIhAPebLe6DOQdlgbNbmf/cFf0W1ZhPYn9q34o/cJvld0GXAiEA47gD3MWRuYLgWOlvRzUHDDFs" +
    "BIpMZ6HBAW2O62QWb08CIQCbRtKwL9S0wcBrxTc3UELt28MJaEdB1rjnDs/ExEtccwIhALj0xz5T" +
    "wi65HFtbDqT5bwX6Rk/RvIkXmln8kG6kVoyFAiB/GaFOt35UYLuJAGZJhBzxf27sSsviwet/Pcdn" +
    "CVosTA==";
  private publickey = "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBANxAlP/bxoO6DB98JXYmgf1o47cqgyQn8CQqVRY9glhj" +
    "lyFFliHRJ9bzuucz1GnMBxO6QfADwf6hDZWeFiQ3tpkCAwEAAQ==";

  constructor(private log: LogService) { }

  encryptRSA(plainText: string): string {
    var encryption = new JSEncrypt();

    encryption.setPublicKey(this.publickey);
    var encoded = encryption.encrypt(plainText);
    //this.log.info(encoded);
    return encoded;
  }

  decryptRSA(encodedText: string): string {
    var encryption = new JSEncrypt();

    encryption.setPrivateKey(this.privatekey);
    var text = encryption.decrypt(encodedText);
    //this.log.info(text);
    return text;
  }

}
