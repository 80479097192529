import { ChannelType } from './channel-type';

export class PaymentSetsRequest {


    channel: ChannelType;
    binNumber: string;
    paymentSystemType: string;
    paymentSystem: string;
    amount: number;
}
