<app-loading [isLoading]="isLoading" [message]="loadingMsg"></app-loading>
<app-loading id="masterpassLoading" [isLoading]="mpIsLoading" [message]="mpLoadingMsg"></app-loading>

<!-- CheckMasterPass -->
<form action="" method="POST" id="checkMP-form" style="display:none">
  <!-- MFS Check MasterPass parameters start -->
  <input type="hidden" name="userId" [(ngModel)]="mpCardPhone"/>
  <input type="hidden" name="token" [(ngModel)]="token"/>
  <input type="hidden" name="referenceNo" [(ngModel)]="mpReferenceNo"/>
  <input type="hidden" name="sendSmsLanguage" [(ngModel)]="sendSmsLanguage"/>
  <!-- MFS Check MasterPass operation parameters end -->
  <!-- MFS constant parameters start -->
  <input type="hidden" name="sendSms" value="N"/>
  <!-- MFS constant parameters end -->
</form>

<!-- purchase -->
<form action="" method="POST" id="purchase-form" style="display:none">
  <input type="hidden" name="amount" [(ngModel)]="mpAmount"/>
  <!-- MFS purchase parameters start -->
  <input type="hidden" name="listAccountName" [(ngModel)]="selectedCardName"/>
  <input type="hidden" name="msisdn" [(ngModel)]="mpCardPhone"/>
  <input type="hidden" name="token" [(ngModel)]="token"/>
  <input type="hidden" name="referenceNo" [(ngModel)]="mpReferenceNo"/>
  <input type="hidden" name="sendSmsLanguage" [(ngModel)]="sendSmsLanguage"/>
  <input type="hidden" name="macroMerchantId" [(ngModel)]="macroMerchantID"/>
  <input type="hidden" name="orderNo" [(ngModel)]="orderId"/>
  <!-- MFS purchase operation parameters end -->
  <!-- MFS constant parameters start -->
  <input type="hidden" name="sendSms" value="N"/>
  <input type="hidden" name="aav" value="aav"/>
  <input type="hidden" name="clientIp" value=""/>
  <input type="hidden" name="encCPin" value="0"/>
  <input type="hidden" name="encPassword" value=""/>
  <input type="hidden" name="sendSmsMerchant" value="Y"/>
  <input type="hidden" name="password" value=""/>
  <!-- MFS constant parameters end -->
</form>

<!-- PurchaseAndRegister -->
<form action="" method="POST" id="purchaseAndRegister-form" style="display:none">
  <input type="hidden" name="accountAliasName" [(ngModel)]="mpCardName"/>
  <input type="hidden" name="rtaPan" [(ngModel)]="paymentProcessRequest.cardNumber"/>
  <input type="hidden" name="expiryDate" [(ngModel)]="mpExpirationDate"/>
  <input type="hidden" name="cvc" [(ngModel)]="paymentProcessRequest.cvcNumber"/>
  <input type="hidden" name="cardHolderName" [(ngModel)]="paymentProcessRequest.cardHolderName"/>
  <input type="hidden" name="amount" [(ngModel)]="mpAmount"/>
  <!-- MFS purchase and register parameters start -->
  <input type="hidden" name="msisdn" [(ngModel)]="mpCardPhone"/>
  <input type="hidden" name="token" [(ngModel)]="token"/>
  <input type="hidden" name="referenceNo" [(ngModel)]="mpReferenceNo"/>
  <input type="hidden" name="sendSmsLanguage" [(ngModel)]="sendSmsLanguage"/>
  <input type="hidden" name="macroMerchantId" [(ngModel)]="macroMerchantID"/>
  <input type="hidden" name="orderNo" [(ngModel)]="orderId"/>
  <!-- MFS purchase and register operation parameters end -->
  <!-- MFS constant parameters start -->
  <input type="hidden" name="sendSms" value="N"/>
  <input type="hidden" name="actionType" value="A"/>
  <!-- MFS constant parameters end -->
</form>

<!-- LinkCardtoClient -->
<form action="" method="POST" id="linkCardToClient-form" style="display:none">
  <!-- MFS LinkCardToClient parameters start -->
  <input type="hidden" name="msisdn" [(ngModel)]="mpCardPhone"/>
  <input type="hidden" name="token" [(ngModel)]="token"/>
  <input type="hidden" name="referenceNo" [(ngModel)]="mpReferenceNo"/>
  <input type="hidden" name="sendSmsLanguage" [(ngModel)]="sendSmsLanguage"/>
  <!-- MFS LinkCardToClient operation parameters end -->
  <!-- MFS constant parameters start -->
  <input type="hidden" name="sendSms" value="N"/>
  <!-- MFS constant parameters end -->
</form>

<!-- updateUser -->
<form action="" method="POST" id="updateUser-form" style="display:none">
  <input type="hidden" name="theNewValue" [(ngModel)]="mpUserId"/>
  <!-- MFS update user parameters start -->
  <input type="hidden" name="valueType" value="USER_ID"/>
  <input type="hidden" name="msisdn" [(ngModel)]="mpCardPhone"/>
  <input type="hidden" name="token" [(ngModel)]="token"/>
  <input type="hidden" name="referenceNo" [(ngModel)]="mpReferenceNo"/>
  <input type="hidden" name="sendSmsLanguage" [(ngModel)]="sendSmsLanguage"/>
  <!-- MFS purchase operation parameters end -->
  <!-- MFS constant parameters start -->
  <input type="hidden" name="sendSms" value="N"/>
  <!-- MFS constant parameters end -->
</form>

<!-- deleteCard -->
<form action="" method="POST" id="deleteCard-form" style="display:none">
  <!-- MFS delete card parameters start -->
  <input type="hidden" name="accountAliasName" value="MyCards"/>
  <input type="hidden" name="msisdn" [(ngModel)]="mpCardPhone"/>
  <input type="hidden" name="token" [(ngModel)]="token"/>
  <input type="hidden" name="referenceNo" [(ngModel)]="mpReferenceNo"/>
  <input type="hidden" name="sendSmsLanguage" [(ngModel)]="sendSmsLanguage"/>
  <!-- MFS delete card operation parameters end -->
  <!-- MFS constant parameters start -->
  <input type="hidden" name="sendSms" value="N"/>
  <!-- MFS constant parameters end -->
</form>

<!-- OTP form -->
<form action="" method="POST" id="otp-form" style="display:none">
  <input type="hidden" name="validationCode" [(ngModel)]="otpValidationCode"/>
  <!-- MFS OTP validation parameters start -->
  <input type="hidden" name="referenceNo" [(ngModel)]="mpReferenceNo"/>
  <input type="hidden" name="sendSms" value="N"/>
  <input type="hidden" name="sendSmsLanguage" [(ngModel)]="sendSmsLanguage"/>
  <input type="hidden" name="pinType" value="otp"/>
  <!-- MFS OTP validation parameters end -->
</form>

<!-- MPIN form -->
<form action="" method="POST" id="mpin-form" style="display:none">
  <input type="hidden" name="validationCode" [(ngModel)]="mpinValidationCode"/>
  <!-- MFS Mpin validation parameters start -->
  <input type="hidden" name="referenceNo" [(ngModel)]="mpReferenceNo"/>
  <input type="hidden" name="sendSms" value="Y"/>
  <input type="hidden" name="sendSmsLanguage" [(ngModel)]="sendSmsLanguage"/>
  <input type="hidden" name="pinType" value="mpin"/>
  <!-- MFS Mpin validation parameters end -->
</form>

<div class="rectangle-first">
  <div
    class="container d-flex justify-content-between align-items-center rectangle-first"
    style="padding-right: 15px !important; padding-left: 15px !important;"
  >
    <div>
      <div class="row align-items-center">
        <img
          src="assets/img/vod-roundel-red.svg"
          style="height: 36px; width: 36px; margin-left: 16px;"
        />
        <div style="margin-left: 9px; margin-bottom: 0; font-size: 18px;">
          Vodafone İş Ortağı
        </div>
      </div>
    </div>
    <div>
      <img src="assets/img/ouno.png" style="height: 36px; width: auto;"/>
    </div>
  </div>
</div>

<div class="rectangle-second" *ngIf="!isLoading && showCardBody">
  <div class="container">
    <div
      class="row d-flex align-items-center rectangle-second"
    >
      <div class="col" style="color: #999999;">
        <div class="text-center font-opt">
          <img
            src="assets/img/group-14.svg"
            style="height: 24px; width: auto; margin: 0px 8px;"
          />Sepetim
        </div>
      </div>
      <div class="col" style="color: #999999;">
        <div class="text-center font-opt">
          <img
            src="assets/img/group-14.svg"
            style="height: 24px; width: auto; margin: 0px 8px;"
          />Teslimat
        </div>
      </div>
      <div class="col">
        <div class="text-center font-opt">
          <img
            src="assets/img/3.png"
            style="height: 24px; width: auto; margin: 0px 8px;"
          /><b style="white-space: nowrap;">Ödeme</b>
        </div>
      </div>
      <div class="col">
        <div class="text-center font-opt">
          <img
            src="assets/img/4.png"
            style="height: 24px; width: auto; margin: 0px 8px;"
          />Sonuç
        </div>
      </div>
    </div>
  </div>

  <div class="rectangle-body" *ngIf="!isError">
    <div class="container">
      <div class="row">
        <div class="col-sm-7">
          <div class="card-body">
            <div class="Masterpass-card">
              <div class="Masterpass-card-list"></div>
            </div>
            <div class="new-card">
              <div class="Mastercard-option-container">
                <div class="Mastercard-option">
                  <div class="Mastercard-option-title">Masterpass ile hızlı ve güvenli alışveriş deneyimi</div>
                  <div class="Mastercard-option-text">Girdiğiniz cep telefonu ile bağlantılı Masterpass hesabı ile ödeme
                    yapmak için telefonunuza tek kullanımlık güvenlik kodu SMS olarak gönderilecektir.
                  </div>
                  <div class="d-none d-sm-block" style="margin-top: 28px"><img src="assets/img/masterpass.png"
                                                                               style="height: auto; width: 30%; padding-right: 20px;"/>
                    <button class="Mastercard-option-button" style="width: 70%;" (click)="processLinkCardToClient()">
                      Kayıtlı kartlarımı getir
                    </button>
                  </div>
                  <div class="d-block d-sm-none justify-content-center" style="margin-top: 28px">
                    <button class="Mastercard-option-button" style="width: 100%;" (click)="processLinkCardToClient()">
                      Kayıtlı kartlarımı getir
                    </button>
                    <div style="display:flex;justify-content:center;align-items:center;margin-top: 10px"><img
                      src="assets/img/masterpass.png" style="height: auto; width: 60%"/></div>
                  </div>
                </div>
                <hr/>
              </div>
              <h5>Kart Bilgileri</h5>
              <br/>
              <div class="form-group">
                <label
                  for="cardnumber"
                  [ngStyle]="msgCardnumber ? { color: 'red' } : {}"
                >Kart Numarası</label
                >
                <p-inputMask
                  id="cardnumber"
                  name="cardnumber"
                  styleClass="form-control"
                  [(ngModel)]="paymentProcessRequest.cardNumber"
                  required
                  mask="9999 9999 9999 9999"
                  placeholder="____ ____ ____ ____"
                  [autoClear]="false"
                  unmask="true"
                  [style]="msgCardnumber ? { borderColor: 'red' } : {}"
                  (onFocus)="msgCardnumber = ''"
                ></p-inputMask>
                <p-message
                  style="font-size: smaller; color: red;"
                  severity="error"
                  [text]="msgCardnumber"
                  *ngIf="msgCardnumber"
                ></p-message>
              </div>
              <div class="form-group">
                <label
                  for="cardHolderName"
                  [ngStyle]="msgCardHolderName ? { color: 'red' } : {}"
                >Kart Üzerindeki İsim</label
                >
                <input
                  [(ngModel)]="paymentProcessRequest.cardHolderName"
                  type="text"
                  class="form-control"
                  id="cardHolderName"
                  name="cardHolderName"
                  required
                  maxlength="30"
                  title="Kart Üzerindeki İsim "
                  pInputText
                  [pKeyFilter]="ccNameRegex"
                  [ngStyle]="msgCardHolderName ? { borderColor: 'red' } : {}"
                  (focus)="msgCardHolderName = ''"
                />

                <p-message
                  style="font-size: smaller; color: red;"
                  severity="error"
                  [text]="msgCardHolderName"
                  *ngIf="msgCardHolderName"
                ></p-message>
              </div>
              <div class="row">
                <div class="col-sm">
                  <div class="form-group">
                    <label
                      for="expiration"
                      [ngStyle]="msgExpiration ? { color: 'red' } : {}"
                    >Son Kullanma Tarihi</label
                    >
                    <p-inputMask
                      id="expiration"
                      name="expiration"
                      styleClass="form-control"
                      [(ngModel)]="paymentProcessRequest.expiration"
                      required
                      mask="99/99"
                      placeholder="__/__"
                      [autoClear]="false"
                      (ngModelChange)="expirationChanged($event)"
                      unmask="true"
                      [style]="msgExpiration ? { borderColor: 'red' } : {}"
                      (onFocus)="msgExpiration = ''"
                    ></p-inputMask>
                    <p-message
                      style="font-size: smaller; color: red;"
                      severity="error"
                      [text]="msgExpiration"
                      *ngIf="msgExpiration"
                    ></p-message>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label
                      for="cvcNumber"
                      [ngStyle]="msgCvcNumber ? { color: 'red' } : {}"
                    >Güvenlik Kodu</label
                    >

                    <div style="position: relative;">
                      <p-inputMask
                        id="cvcNumber"
                        name="cvcNumber"
                        styleClass="form-control"
                        [(ngModel)]="paymentProcessRequest.cvcNumber"
                        required
                        mask="999"
                        placeholder="___"
                        [autoClear]="false"
                        unmask="true"
                        [style]="msgCvcNumber ? { borderColor: 'red' } : {}"
                        (onFocus)="msgCvcNumber = ''"
                      ></p-inputMask>
                      <span
                        style="
                        position: absolute;
                        right: 0;
                        top: 1px;
                        padding: 3px;
                        padding-right: 10px;
                      "
                      >
                      <img src="assets/img/group-8.svg"/>
                    </span>
                      <p-message
                        style="font-size: smaller; color: red;"
                        severity="error"
                        [text]="msgCvcNumber"
                        *ngIf="msgCvcNumber"
                      ></p-message>
                    </div>
                  </div>
                </div>
              </div>

              <!--masterpass-->
              <div class="col-sm" style="padding: 0px">
                <div class="form-check" style="padding: 0px">
                  <div class="container">
                    <div class="row">
                      <div style="width: 58%">
                        <label class="form-check-label" for="checkMP" style="font-size: 14px;">
                          <input type="checkbox" class="form-check-input" id="checkMP" [(ngModel)]="mpCheck"
                                 class="checkbox" style="margin-right: 5px;"/>
                          Kartımı sonraki işlemler için Masterpass ile kaydet.
                        </label>
                      </div>
                      <div style="width: 42%">
                        <img src="assets/img/masterpass.png" style="height: 30px; width: auto;"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group" style="padding-top: 5px" *ngIf="mpCheck">
                  <input
                    [(ngModel)]="mpCardName"
                    type="text"
                    class="form-control"
                    required
                    maxlength="30"
                    placeholder="Kartınıza vermek istediğiniz isim*"
                    pInputText
                  />
                </div>
                <div *ngIf="mpCheck" class="Mastercard-description">
                  Mastercard'ın genel <a href="https://www.masterpassturkiye.com/TermsAndConditions.aspx" target="_blank"><span class="text-style-1">Hüküm ve Koşullarını</span></a> okudum ve onaylıyorum. Kart bilgileriniz Vodafone tarafından saklanmamaktadır, ödeme altyapısı Mastercard tarafından sağlanmaktadır.
                </div>
              </div>
            </div>

            <hr/>

            <div class="form-group rectangle-info">
              <div class="media align-items-center">
                <div class="media-left">
                  <img src="assets/img/e-60000.svg"/>
                </div>
                <div class="media-body" style="margin-left: 6px;">
                  <span [innerHTML]="payment?.description | boldMoney"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-5">
          <div class="card-body">
            <ng-container *ngFor="let paymentItem of payment?.paymentItem">
              <div
                class="d-flex justify-content-between"
                *ngIf="paymentItem.item['@referredType'] === 'ScreenText'"
                style="padding-top: 10px; padding-bottom: 10px;"
              >
                <div>
                  <span style="white-space: pre-line;" [innerHTML]="normalizeSpace(paymentItem?.item?.name)"></span>
                </div>
                <div>
                  <span *ngIf="paymentItem?.amount?.unit?.includes('₺')"
                    ><b>{{ paymentItem?.amount?.value }} {{ paymentItem?.amount?.unit }}</b></span>
                  <span *ngIf="!paymentItem?.amount?.unit?.includes('₺')"><b>{{ paymentItem?.amount?.value }}  {{ paymentItem?.amount?.unit}}</b></span>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="form-group" style="margin-top: 1rem;">
            <img
              src="assets/img/ic-security-red.svg"
              style="
                height: 32px;
                width: 32px;
                margin-left: 16px;
                display: inline;
              "
            />
            <div style="display: inline;">
              <bold>Ödemeniz 3D Secure ile güvendedir.</bold>
            </div>
          </div>

          <div
            *ngFor="let paymentItem of payment?.paymentItem"
            style="margin-top: 10px;"
          >
            <div *ngIf="paymentItem.item['@referredType'].includes('Button')">
              <button
                type="button"
                [disabled]="isLoading"
                class="btn btn-primary btn-lg btn-block"
                style="margin-bottom: 10px;"
                (click)="processPayment()"
              >
                {{ isLoading ? "Ödeme alınıyor" : paymentItem.item.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="!isLoading && isError">
  <div class="card-body-error text-center" style="position: relative;">
    <span
      style="
        color: white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-20%, -70%);
      "
    >
      <img
        src="assets/img/alert-triangle.svg"
        style="width: 42px; height: 42px;"
      />
    </span>
    <div>
      {{ errorMsg }}
    </div>
  </div>

  <div>
    <div class="row" class="text-center" style="margin: 25px;">
      <button class="btn btn-primary btn-retry" (click)="retryOnError()">
        Tekrar Dene
      </button>
    </div>
    <div class="row" class="text-center" style="margin: 25px;">
      <button class="btn btn-primary btn-back" (click)="backOnError()">
        Geri Dön
      </button>
    </div>
  </div>
</div>

<div class="modal fade" id="otp-form-bank-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div style="height: 32px">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" style="font-size: 32px;">&times;</span>
          </button>
        </div>
        <div style="margin-right:50px;margin-left:50px;margin-bottom: 30px">
          <h4 style="text-align: center;">Onay kodunu girin</h4>
          <br/>
          <div style="padding:5px;border-radius: 6px;border: solid 1px #ebebeb;background-color: #f5f4f4;">
            <p style="text-align: center;">Lütfen bankanız tarafından telefonunuza gönderilen tek kullanımlık doğrulama kodunu giriniz.</p>
            <p *ngIf="this.otpModalTimer != ''" style="text-align: center;">Kalan süreniz: {{this.otpModalTimer}} saniye</p>
            <p *ngIf="this.otpModalTimer == ''" style="text-align: center;">Süreniz doldu. Tekrar doğrulama kodu gönder tuşuna basınız.</p>
          </div>
          <br/>
          <input
            id="otp-form-bank-modal-input"
            [(ngModel)]="otpValidationCode"
            type="password"
            class="form-control"
            required
            maxlength="6"
            placeholder="_ _ _ _ _ _"
            pInputText
          />
          <br/>
          <button id="otp-form-bank-modal-button" type="button" class="btn btn-primary btn-lg btn-block" data-dismiss="modal" (click)="processOtpValidation()">Doğrula</button>
          <br/>
          <button type="button" class="btn btn-link btn-block" [disabled]="resendOtpDisabled" (click)="resendOtp()">Tekrar Doğrulama Kodu Gönder {{this.resendOtpTimer}}</button>
          <div style="display:flex;justify-content:center;align-items:center;"><img src="assets/img/masterpass.png" style="height: 42px; width: auto;"/></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="otp-form-masterpass-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div style="height: 32px">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" style="font-size: 32px;">&times;</span>
          </button>
        </div>
        <div style="margin-right:50px;margin-left:50px;margin-bottom: 30px">
          <h4 style="text-align: center;">Onay kodunu girin</h4>
          <br/>
          <div style="padding:5px;border-radius: 6px;border: solid 1px #ebebeb;background-color: #f5f4f4;">
            <p style="text-align: center;">Son 4 hanesi {{mpCardPhone.substring(mpCardPhone.length -4)}} olan hattınıza Masterpass tarafından gönderilen tek kullanımlık doğrulama kodunu giriniz.</p>
            <p *ngIf="this.otpModalTimer != ''" style="text-align: center;">Kalan süreniz: {{this.otpModalTimer}} saniye</p>
            <p *ngIf="this.otpModalTimer == ''" style="text-align: center;">Süreniz doldu. Tekrar doğrulama kodu gönder tuşuna basınız.</p>
          </div>
          <br/>
          <input
            id="otp-form-masterpass-modal-input"
            [(ngModel)]="otpValidationCode"
            type="password"
            class="form-control"
            required
            maxlength="6"
            placeholder="_ _ _ _ _ _"
            pInputText
          />
          <br/>
          <button id="otp-form-masterpass-modal-button" type="button" class="btn btn-primary btn-lg btn-block" data-dismiss="modal" (click)="processOtpValidation()">Doğrula</button>
          <br/>
          <button type="button" class="btn btn-link btn-block" [disabled]="resendOtpDisabled" (click)="resendOtp()">Tekrar Doğrulama Kodu Gönder {{this.resendOtpTimer}}</button>
          <div style="display:flex;justify-content:center;align-items:center;"><img src="assets/img/masterpass.png" style="height: 42px; width: auto;"/></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="mpin-form-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <img src="assets/img/masterpass.png" style="height: 36px; width: auto;"/>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5 class="modal-title">MPIN Doğrulaması</h5>
        <p>Lütfen MPIN şifrenizi giriniz.</p>
        <input
          [(ngModel)]="mpinValidationCode"
          type="text"
          class="form-control"
          required
          maxlength="30"
          pInputText
        />
        <br/>
        <button type="button" class="btn btn-info" data-dismiss="modal" (click)="processMpinValidation()">Doğrula</button>
        <div style="display:flex;justify-content:center;align-items:center;"><img src="assets/img/masterpass.png" style="height: 42px; width: auto;"/></div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteCard-form-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <img src="assets/img/masterpass.png" style="height: 36px; width: auto;"/>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Masterpass’e kayıtlı kartınızı silmek istediğinizden emin misiniz ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Hayır</button>
        <button type="button" class="btn btn-info" data-dismiss="modal" (click)="processDeleteCard()">Evet</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="alert-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <img src="assets/img/masterpass.png" style="height: 36px; width: auto;"/>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div id="alert-text" class="modal-body">Hatalı işlem</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info" data-dismiss="modal" (click)="closeAlertModel()">Tamam</button>
      </div>
    </div>
  </div>
</div>
