import { Money } from './money';
import { EntityRef } from './entity-ref';
import { PaymentTypeItem } from './payment-type-item';

export class PaymentSystem {
    
    name: string;
    type: string;
    installmentList: PaymentTypeItem[];
    
}