import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Money} from 'src/app/model/money';
import {Payment} from 'src/app/model/payment';
import {PaymentItem} from 'src/app/model/payment-item';
import {PaymentProcessRequest} from 'src/app/model/payment-process-request';
import {ResponseData} from 'src/app/model/response-data';
import {OrderService} from 'src/app/service/order.service';
import {PaymentService} from 'src/app/service/payment.service';
import {PaymentOrderIdRequest} from 'src/app/model/payment-order-id-request';
import {EncriptionService} from 'src/app/service/encription-service';
import {ChannelType} from 'src/app/model/channel-type';
import {FormBuilder} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {PaymentWrapper} from 'src/app/model/payment-wrapper';
import {PaymentMapping} from 'src/app/model/payment-mapping';
import {environment} from 'src/environments/environment';
import {LogService} from 'src/app/service/log.service';
import {B2bPaymentType} from 'src/app/model/b2b-payment-type';
import {MasterpassTokenRequest} from 'src/app/model/master-pass-token-request';
import {MasterPassPurchaseRequest} from "../../model/master-pass-purchase-request";
import {MasterPassPurchaseResponse} from "../../model/master-pass-purchase-response";

declare function forwardSuccess(): any; // for mobil app
declare function forwardDelivery(): any; // for mobil app

declare var MFS: any;
declare var $: any;

function mfsCheckMasterPassResponseHandler(status, response) {
  console.log(response);
  if (response.responseCode == "0000" || response.responseCode == "") {
    console.log(response.accountStatus);
    let accountStatus = response.accountStatus.substring(1,5);
    localStorage.setItem("accountStatus",accountStatus);
    if(accountStatus == "1100" || accountStatus == "1110"){
      $(".Mastercard-option-container").show();
    }
  } else {
    console.log(response.responseDescription);
    //alert(response.responseDescription);
    //$('#alert-text').html(response.responseDescription);
    //$('#alert-modal').modal('show');
  }
}

function mfsResponseHandler(status, response) {
  console.log(response);
  if (response.responseCode == "0000" || response.responseCode == "") {
    let mfsService = localStorage.getItem("mfsService");
    if(mfsService == "updateUser"){
      localStorage.setItem("updateUser","true");
      console.log("UserId Update basarili");
      let afterUpdateUser = localStorage.getItem("afterUpdateUser");
        if(afterUpdateUser == "listCards"){
        let cardPhone = localStorage.getItem("cardPhone");
        let token = localStorage.getItem("token");
        MFS.listCards(cardPhone, token, listCardsResponseHandler);
      }else if(afterUpdateUser == "purchaseAndRegister"){
        $(function () {
          $('#purchaseAndRegister-form').submit();
        });
      }
    } else if(mfsService == "linkCardToClient"){
      console.log("Link islemi basarili");
      let cardPhone = localStorage.getItem("cardPhone");
      let token = localStorage.getItem("token");
      MFS.listCards(cardPhone, token, listCardsResponseHandler);
    } else{
      console.log("islem basarili");
    }
  } else {
    if (response.responseCode == "5001") { // OTP sor
      $('#otp-form-bank-modal').modal('show');
    } else if (response.responseCode == "5008") { // MasterPass OTP sor
      $('#otp-form-masterpass-modal').modal('show');
    } else if (response.responseCode == "5010") { // 3D Secure'e yonlendir
      window.location.assign(response.url3D + "&returnUrl=" + environment.apiUrl + "/public/payment/masterPass/commit");
    } else if (response.responseCode == "5015") { // MPIN belirle
      $('#mpin-form-modal').modal('show');
    } else {
      //alert(response.responseDescription);
      $('#alert-text').html(response.responseDescription);
      $('#alert-modal').modal('show');
    }
  }
  $("#masterpassLoading").hide();
}

function listCardsResponseHandler(statusCode, response){
  if (response.responseCode != "0000" && response.responseCode != "")
  {
    if(response.responseDescription == "There is no account owned by this phone number."){
      localStorage.setItem("mpVerify","false");
      localStorage.setItem("accountStatus","0000");
      $('.card-div').remove();
      $(".new-card").show();
    }
    console.log(response.responseDescription);
    return;
  }
  localStorage.setItem("mpVerify","true");
  $('.card-div').remove();
  $(".Masterpass-card-list").append(
    '<div>'+
    '<h5 class="col-md-8 col-sm-12" style="float:left;padding:0;align-items:center">Masterpass’e Kayıtlı Kartlarım</h5>' +
    '<img src="assets/img/masterpass.png" class="col-md-4 d-none d-md-block" style="height:auto;width:30%;float:right;padding:0;"</img>' +
    '</div>' +
    '<br/>' +
    '<br style="clear:both;"/>'
  );
  for (let i = 0; i < response.cards.length; i++) {
    let card = response.cards[i];
    $(".Masterpass-card-list").append(
      '<div class="card-div" style="display:flex;flex-direction:row;justify-content:center;align-items:center;">' +
      '<input type="radio" name="payment" style="width:10%;height:20px;border-radius:3px;border:solid 1px #999999;background-color:#ffffff;" value="' + card.Name + '"> ' +
      '<div style="height:46px;width:70%;">' +
      '<div style="font-size:12px;">' + card.Name + '</div>' + '<div style="font-size:20px;">' + card.Value1 + '</div>' +
      '</div>' +
      '<button name="deleteCard" class="btn btn-link" style="width:30%;font-size:14px;" value="' + card.Name + '"><div class="d-none d-sm-block">Kartımı Sil</div><img alt="trash-bin" width="26px" src="assets/img/ic-delete.png"/></button>' +
      '</div>'
    );
    $(".Masterpass-card-list").append('<div class="card-div"><hr/></div>');
  }
  $(".Masterpass-card-list").append(
    '<div class="card-div" style="display:flex;flex-direction:row;justify-content:center;align-items:center;">' +
    '<input type="radio" name="payment" style="width:10%;height:20px;border-radius:3px;border:solid 1px #999999;background-color:#ffffff;vertical-align:middle;" value="new">' +
    '<div style="width:90%">Başka bir kart ile öde</div></div>' +
    '<div class="new-card card-div"><br/></div>'
  );
  $(".Mastercard-option-container").hide();
  $(".new-card").hide();
  $("input[name='payment']").on( "click", function() {
    console.log($("input:checked").val());
    if($("input:checked").val() == "new"){
      $(".new-card").show();
    }else{
      $(".new-card").hide();
    }
  });
  $("button[name='deleteCard']").on( "click", function() {
    localStorage.setItem("selectedCardName",$(this).val());
    $('#deleteCard-form-modal').modal('show');
  });
}

@Component({
  selector: 'vodafone-payment-test',
  templateUrl: './vodafone-payment-test.component.html',
  styleUrls: ['./vodafone-payment-test.component.css']
})
export class VodafonePaymentTestComponent implements OnInit {

  orderIdRequest = new PaymentOrderIdRequest();
  orderId: string;
  paymentProcessRequest = new PaymentProcessRequest();
  masterPassPurchaseRequest = new MasterPassPurchaseRequest();
  isLoading = false;
  loadingMsg: string;
  paymentWrapper: PaymentWrapper;
  payment: Payment;
  paymentMapping: PaymentMapping;
  ccNameRegex: RegExp = /^[a-zA-Z ğüşöçİĞÜŞÖÇ]*$/i;
  //ccExpRegex: RegExp = /^(0[1-9]|1[0-2])[\/]([2-4][0-9])$/i;
  ccExpRegex: RegExp = /^([0-1]|(0[1-9]|1[0-2])|(0[1-9]|1[0-2])([2-4])|((0[1-9]|1[0-2])([2-4][0-9])))$/g;
  ccNumberRegex: RegExp = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

  // error
  isError: boolean;
  errorMsg: string;

  // eshop mobil app
  isMobilApp = false;

  isSuccessPayment: boolean;
  showCardBody: boolean;

  // PROD: eshopCommitSuccessUrl = "https://www.vodafone.com.tr/telefonlar/sonuc";
  // TEST: eshopCommitSuccessUrl = "http://172.30.100.104:7085/tsteShopCBUAPDEV/sonuc";
  eshopCommitSuccessUrl = environment.eshopCommitSuccessUrl;
  // PROD: eshopCommitFailUrl = "https://www.vodafone.com.tr/telefonlar/musteri-bilgileri";
  // TEST: eshopCommitFailUrl = "http://172.30.100.104:7085/tsteShopCBUAPDEV/musteri-bilgileri";
  eshopCommitFailUrl = environment.eshopCommitFailUrl;
  //TEST: mfsAddress = https://test.masterpassturkiye.com/MasterpassJsonServerHandler/v2
  mfsAddress = environment.mfsAddress;
  mfsClientId = environment.mfsClientId;
  macroMerchantID = environment.macroMerchantID;

  // err msgs
  msgCardHolderName: string;
  msgCardnumber: string;
  msgExpiration: string;
  msgCvcNumber: string;
  amount = 1;
  selectedCardName;
  otpValidationCode;
  mpinValidationCode;

  mpCheck;
  mpCardName;
  mpExpirationDate;

  mpCardPhone;
  mpAmount;
  mpUserId;
  mpReferenceNo;

  token;
  sendSmsLanguage;
  sendSms;

  mpVerify = false;

  mpIsLoading = false;
  mpLoadingMsg;

  resendOtpDisabled = false;
  resendOtpTimer;
  otpModalTimer;

  constructor(private fb: FormBuilder, private messageService: MessageService, private router: Router, private route: ActivatedRoute, private paymentService: PaymentService,
              private orderService: OrderService, private encriptionService: EncriptionService, private log: LogService) {
  }

  ngOnInit(): void {
    localStorage.clear();

    MFS.setAddress(this.mfsAddress);
    MFS.setClientId(this.mfsClientId);

    this.mpCardPhone = "905394203347";
    this.mpUserId = Math.floor(100000000 + Math.random() * 900000000);
    this.mpReferenceNo = Math.floor(100000000 + Math.random() * 900000000);
    localStorage.setItem('cardPhone', this.mpCardPhone);
    localStorage.setItem('userId', this.mpUserId);
    localStorage.setItem('referenceNo', this.mpReferenceNo);
    localStorage.setItem("mpVerify","false");
    localStorage.setItem("updateUser","false");

    this.sendSmsLanguage = 'tur';
    this.sendSms = 'N';

    this.mpLoadingMsg = "MasterPass İşlemleri Gerçekleştiriliyor...";
    this.resendOtpTimer = "";
    this.otpModalTimer = "";

    var encodedOrderId = this.route.snapshot.queryParamMap.get("id");
    this.log.info(encodedOrderId);

    this.orderId = this.encriptionService.decryptRSA(decodeURI(encodedOrderId));
    this.log.info(this.orderId);
    if (!this.orderId) {
      //this.isError = true;
      //this.errorMsg = "Sipariş bilgisi bulunamadı!";
      //this.log.error("order id not found!");

      this.isLoading = true;
      this.loadingMsg = "Güvenli ödeme bilgileri sayfasına yönlendiriliyorsunuz.";

      var orderId = Date.now() + "";
      var encodedOrderId = this.encriptionService.encryptRSA(orderId);

      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['vodafone-payment-test'], {queryParams: {id: encodeURI(encodedOrderId)}});
      });

    } else {
      this.getPaymentDetails();
    }
  }

  getPaymentDetails() {
    this.isLoading = true;
    this.loadingMsg = "Güvenli ödeme bilgileri sayfasına yönlendiriliyorsunuz.";

    this.masterPassPurchaseRequest.channel = ChannelType.eshop;
    this.masterPassPurchaseRequest.orderId = this.orderId;
    this.masterPassPurchaseRequest.masterPassReferenceNo = this.mpReferenceNo;
    this.masterPassPurchaseRequest.returnUrl = location.href;

    this.orderIdRequest.orderId = this.orderId;
    this.orderIdRequest.channel = "b2b";
    this.orderIdRequest.paymentType = B2bPaymentType.exact; // TODO

    this.payment = new Payment();
    this.payment.amount = new Money();
    this.payment.paymentItem = new PaymentItem()[1];

    return this.orderService.getB2bPaymentByOrderId(this.orderIdRequest)
      .subscribe((res: any) => {

        this.paymentWrapper = res.data;
        // this.payment = this.paymentWrapper.b2bPayment;
        this.amount = this.paymentWrapper?.b2bPayment?.amount;
        this.mpAmount = this.amount * 100;
        this.payment.description = "Vodafone Online Mağazadan aldığınız ürünlerin ön ödemesi olan ₺" + this.amount
          + " kredi kartınızdan tahsil edilecektir. Kalan ₺165.00 x 12 aylık ödeme faturanızdan tahsil edilecektir.";
        this.payment.paymentItem = [
          {
            "amount": {
              "unit": "₺",
              "value": 165.00
            },
            "item": {
              "name": "\"Kalan tarifeye ek tutar /n 12 ay\"",
              "@referredType": "ScreenText"
            }
          },
          {
            "amount": {
              "unit": "₺",
              "value": this.amount
            },
            "item": {
              "name": "\"Kredi kartı ile ödenecek tutar /n Ön ödeme\"",
              "@referredType": "ScreenText"
            }
          },
          {
            "amount": null,
            "item": {
              "name": "Kargo",
              "@referredType": "ScreenText"
            }
          },
          {
            "amount": null,
            "item": {
              "name": "Normal Teslimat",
              "@referredType": "ScreenText"
            }
          },
          {
            "amount": null,
            "item": {
              "name": this.amount + " TL Öde ve Siparişi Tamamla",
              "@referredType": "Button Label"
            }
          }
        ];
        this.paymentMapping = this.paymentWrapper.paymentMapping;
        //this.log.info(res);

        // for app
        //this.isMobilApp = this.payment.channel.name === 'app';
        //this.log.info("valid to : " + new Date(this.paymentMapping.validDate));

        /*
        if (new Date(this.paymentMapping.validDate) < new Date()) {
          this.isError = true;
          this.errorMsg = "Sayfa zaman aşımına uğradı.";
          this.isLoading = false;
          return;
        }*/

        if (this.paymentMapping.isSuccess == true) {
          this.isSuccessPayment = true;
          this.callOnSuccess();
          return;
        }

        // for error
        if (this.paymentMapping.isSuccess == false) {
          this.isError = true;
          this.errorMsg = this.paymentMapping.errorMsg;
          this.isLoading = false;
          return;
        }

        if (!this.paymentProcessRequest) {
          this.paymentProcessRequest = new PaymentProcessRequest();
        }

        this.paymentProcessRequest.pos = "ZİRAAT TEST";
        this.paymentProcessRequest.channel = ChannelType.b2b;
        this.paymentProcessRequest.orderId = this.orderId;
        this.paymentProcessRequest.amount = this.amount;
        this.paymentProcessRequest.installment = 1;
        /*this.paymentProcessRequest.cardHolderName = "A B";
        this.paymentProcessRequest.cardNumber = "4355084355084358";
        this.paymentProcessRequest.cvcNumber = "000";
        this.paymentProcessRequest.expMonth = 12;
        this.paymentProcessRequest.expYear = 2030
        this.paymentProcessRequest.expiration = 1230 + "";*/
        this.isLoading = false;
        this.showCardBody = true;

        let request = new MasterpassTokenRequest();
        request.msisdn = this.mpCardPhone;
        request.reqRefNo = this.mpReferenceNo;
        request.userId = this.mpUserId;
        this.paymentService.masterPassToken(request)
          .subscribe((res: ResponseData) => {
            this.token = res.data.token;
            localStorage.setItem('token', this.token);
            $(function () {
              $('#purchaseAndRegister-form').submit(function(event) {
                $("#masterpassLoading").show();
                MFS.purchaseAndRegister($(this), mfsResponseHandler);
                return false;
              });
              $('#purchase-form').submit(function(event) {
                $("#masterpassLoading").show();
                MFS.purchase($(this), mfsResponseHandler);
                return false;
              });
              $('#otp-form, #mpin-form').submit(function(event) {
                $("#masterpassLoading").show();
                MFS.validateTransaction($(this), mfsResponseHandler);
                return false;
              });
              $('#linkCardToClient-form').submit(function(event) {
                $("#masterpassLoading").show();
                MFS.linkCardToClient($(this), mfsResponseHandler);
                return false;
              });
              $('#updateUser-form').submit(function(event) {
                $("#masterpassLoading").show();
                MFS.updateUser($(this), mfsResponseHandler);
                return false;
              });
              $('#deleteCard-form').submit(function(event) {
                $("#masterpassLoading").show();
                this.accountAliasName.value = localStorage.getItem("selectedCardName");
                console.log(this.accountAliasName.value);
                MFS.deleteCard($(this), mfsResponseHandler);
                return false;
              });
              $('#checkMP-form').submit(function (event) {
                MFS.checkMasterPass($(this), mfsCheckMasterPassResponseHandler);
                return false;
              });
              $('#checkMP-form').submit();
            });
          }, err => {
            console.error(err);
          });
      }, err => {
        this.log.error(err);
        this.isError = true;
        this.errorMsg = "Sipariş bilgisi bulunamadı!";
        this.isLoading = false;
      });
  }

  processPayment() {
    this.otpValidationCode = "";
    this.mpinValidationCode = "";
    let mpVerify = localStorage.getItem("mpVerify");
    let accountStatus = localStorage.getItem("accountStatus");
    let updateUser = localStorage.getItem("updateUser");
    if(mpVerify == "false"){
      if(!this.mpCheck){
        alert("Masterpass`siz ödeme işlemi teste dahil değil!");
      }else{
        if (this.mpCardName != null) {
          this.mpIsLoading = true;
          $("#masterpassLoading").show();
          this.paymentService.masterPassPurchase(this.masterPassPurchaseRequest).subscribe((res: ResponseData) => {
            this.log.info(res);
            var paymentProcessResponse: MasterPassPurchaseResponse = res.data;
            if(paymentProcessResponse.success) {
              if (this.paymentProcessRequest.expiration.length >= 4) {
                this.mpExpirationDate = this.paymentProcessRequest.expiration.substring(2, 4) + this.paymentProcessRequest.expiration.substring(0, 2);
              }
              if (accountStatus == "1110" && updateUser == "false") {
                this.openDisabled();
                if(this.otpModalTimer == ''){
                  this.otpModalTimer = 180;
                  this.otpModelTimerCountdown();
                }else{
                  this.otpModalTimer = 180;
                }
                $(function () {
                  localStorage.setItem("afterUpdateUser", "purchaseAndRegister");
                  localStorage.setItem("mfsService", "updateUser");
                  $('#updateUser-form').submit();
                });
              } else {
                this.openDisabled();
                if(this.otpModalTimer == ''){
                  this.otpModalTimer = 180;
                  this.otpModelTimerCountdown();
                }else{
                  this.otpModalTimer = 180;
                }
                $(function () {
                  $('#purchaseAndRegister-form').submit();
                });
              }
            }
          }, err => {
            this.log.error(err);
          });
        } else {
          $('#alert-text').html('Kartınıza vermek istediğiniz isim alanı dolu olmalıdır.');
          $('#alert-modal').modal('show');
        }
      }
    }else{
      var radioValue = $("input:checked").val();
      if(radioValue){
        if(radioValue != "new"){
          this.mpIsLoading = true;
          $("#masterpassLoading").show();
          this.paymentService.masterPassPurchase(this.masterPassPurchaseRequest).subscribe((res: ResponseData) => {
            this.log.info(res);
            var paymentProcessResponse: MasterPassPurchaseResponse = res.data;
            if(paymentProcessResponse.success) {
              this.selectedCardName = radioValue;
              console.log(this.selectedCardName);
              this.openDisabled();
              if(this.otpModalTimer == ''){
                this.otpModalTimer = 180;
                this.otpModelTimerCountdown();
              }else{
                this.otpModalTimer = 180;
              }
              $(function () {
                $('#purchase-form').submit();
              });
            }
          }, err => {
            this.log.error(err);
          });
        }else{
          if(!this.mpCheck){
            alert("Masterpass`siz ödeme işlemi teste dahil değil!");
          }else {
            this.mpIsLoading = true;
            $("#masterpassLoading").show();
            this.paymentService.masterPassPurchase(this.masterPassPurchaseRequest).subscribe((res: ResponseData) => {
              this.log.info(res);
              var paymentProcessResponse: MasterPassPurchaseResponse = res.data;
              if(paymentProcessResponse.success) {
                if (this.paymentProcessRequest.expiration.length >= 4) {
                  this.mpExpirationDate = this.paymentProcessRequest.expiration.substring(2, 4) + this.paymentProcessRequest.expiration.substring(0, 2);
                }
                this.openDisabled();
                if(this.otpModalTimer == ''){
                  this.otpModalTimer = 180;
                  this.otpModelTimerCountdown();
                }else{
                  this.otpModalTimer = 180;
                }
                $(function () {
                  $('#purchaseAndRegister-form').submit();
                });
              }
            }, err => {
              this.log.error(err);
            });
          }
        }
      }else{
        //alert("Ödeme işlemi için lütfen bir seçim yapınız.");
        $('#alert-text').html("Ödeme işlemi için lütfen bir seçim yapınız.");
        $('#alert-modal').modal('show');
      }
    }
  }

  processLinkCardToClient(){
    this.mpIsLoading = true;
    this.otpValidationCode = "";
    this.mpinValidationCode = "";
    let accountStatus = localStorage.getItem("accountStatus");
    if(accountStatus == "1100"){
      localStorage.setItem("mfsService","linkCardToClient");
      this.openDisabled();
      if(this.otpModalTimer == ''){
        this.otpModalTimer = 180;
        this.otpModelTimerCountdown();
      }else{
        this.otpModalTimer = 180;
      }
      $(function () {
        $('#linkCardToClient-form').submit();
      });
    }else if(accountStatus == "1110"){
      localStorage.setItem("mfsService","updateUser");
      localStorage.setItem("afterUpdateUser","listCards");
      this.openDisabled();
      if(this.otpModalTimer == ''){
        this.otpModalTimer = 180;
        this.otpModelTimerCountdown();
      }else{
        this.otpModalTimer = 180;
      }
      $(function () {
        $('#updateUser-form').submit();
      });
    }
  }

  processOtpValidation() {
    $(function () {
      $('#otp-form').submit();
    });
  }

  resendOtp(){
    $("#masterpassLoading").show();
    this.resendOtpDisabled = true;
    this.resendOtpTimer = 120;
    this.openDisabled();
    if(this.otpModalTimer == ''){
      this.otpModalTimer = 180;
      this.otpModelTimerCountdown();
    }else{
      this.otpModalTimer = 180;
    }
    let token = MFS.getLastToken();
    let lang = "tur";
    MFS.resendOtp(token, lang, mfsResponseHandler);
    this.resendOtpTimerCountdown();
  }

  openDisabled(){
    $('#otp-form-bank-modal-button').prop('disabled', false);
    $('#otp-form-bank-modal-input').prop('disabled', false);
    $('#otp-form-masterpass-modal-button').prop('disabled', false);
    $('#otp-form-masterpass-modal-input').prop('disabled', false);
  }

  resendOtpTimerCountdown(){
    setTimeout(() => {
      if(this.resendOtpTimer <= 1){
        this.resendOtpDisabled = false;
        this.resendOtpTimer = "";
      }else{
        this.resendOtpTimer--;
        this.resendOtpTimerCountdown();
      }
    }, 1000);
  }

  otpModelTimerCountdown(){
    setTimeout(() => {
      if(this.otpModalTimer <= 1){
        $('#otp-form-bank-modal-button').prop('disabled', true);
        $('#otp-form-bank-modal-input').prop('disabled', true);
        $('#otp-form-masterpass-modal-button').prop('disabled', true);
        $('#otp-form-masterpass-modal-input').prop('disabled', true);
        this.otpModalTimer = '';
      }else{
        this.otpModalTimer--;
        this.otpModelTimerCountdown();
      }
    }, 1000);
  }


  processMpinValidation() {
    $(function () {
      $('#mpin-form').submit();
    });
  }

  processDeleteCard() {
    $(function () {
      $('#deleteCard-form').submit();
    });
  }

  closeAlertModel() {
    $('#alert-modal').modal('hide');
  }

  /*
  processPayment() {
    this.isLoading = true;
    this.loadingMsg = "3D güvenlik şifresi için yönlendiriliyorsunuz.";

    if (!this.validateInputs()) {
      //this.messageService.add({severity:'error', summary:'Lütfen tüm alanları doğru girin', detail:''});
      //setTimeout(() => {
      this.isLoading = false;
      //}, 500);
      return;
    }

    if (this.paymentProcessRequest.cardNumber) {
      this.paymentProcessRequest.cardNumber = this.paymentProcessRequest.cardNumber.split(" ").join("");
    }
    if (this.paymentProcessRequest.expiration) {
      this.paymentProcessRequest.expiration = this.paymentProcessRequest.expiration.replace("/", "");
      this.paymentProcessRequest.expMonth = +this.paymentProcessRequest.expiration.substr(0, 2);
      this.paymentProcessRequest.expYear = +("20" + this.paymentProcessRequest.expiration.substr(2, 4));
    }

    this.log.info(this.router.url);
    this.paymentProcessRequest.returnUrl = location.href;

    //this.log.info(this.paymentProcessRequest);
    //this.messageService.add({severity:'info', summary:'Success', detail:'Form Submitted'});

    this.paymentService.processPayment(this.paymentProcessRequest)
      .subscribe((res: ResponseData) => {

        this.log.info(res);

        var paymentProcessResponse: PaymentProcessResponse = res.data;

        if (paymentProcessResponse.threeDHtmlBody) {
          if (paymentProcessResponse.threeDHtmlBody.includes("ErrorCode")
            || paymentProcessResponse.threeDHtmlBody.includes("ErrorMsg")) {
              this.isError = true;
              this.errorMsg = "Ödeme işlemi sırasında hata oluştu. Lütfen tekrar deneyin.";
              this.isLoading = false;
          } else {
            document.open();
            document.write(paymentProcessResponse.threeDHtmlBody);
            document.close();
          }
        } else if (paymentProcessResponse.threeDUrl) {
          window.open(encodeURI(paymentProcessResponse.threeDUrl), "_self");
        } else {
          throw new Error("3D url or body not found!");
        }

      }, err => {
        this.log.error(err);
        this.isLoading = false;
      });
  }*/

  callOnSuccess() {
    this.isLoading = true;
    this.loadingMsg = "Ödeme başarılı. Yönlendirme yapılıyor."
    this.log.info("---- Ödeme başarılı. Yönlendirme yapılıyor ----");
    this.log.info("isMobilApp: " + this.isMobilApp);
    setTimeout(() => {
      if (this.isMobilApp) {
        forwardSuccess();
      } else {
        var returnUrl = this.route.snapshot.queryParamMap.get("returnUrl");
        if(returnUrl != null){
          window.open(encodeURI(returnUrl + "&payment_id=" + this.orderId + "&chatbotMsPaymentOk=true"), "_self");
        }else {
          window.open(encodeURI(this.eshopCommitSuccessUrl), "_self");
        }
      }
      this.isLoading = false;
    }, 500);
  }

  retryOnError() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['vodafone-payment-test']);
    });
    /*this.isLoading = true;
    this.loadingMsg = "Güvenli ödeme bilgileri sayfasına yönlendiriliyorsunuz.";
    setTimeout(() => {
      this.paymentService.clearBeforeRetry(this.orderIdRequest).subscribe(res => {
        this.log.info(res);
        this.log.info("---- Yönlendirme devam ediyor ----");
        location.reload();
        //this.isLoading = false;
      }, err => {
        this.log.error(err);
        this.isLoading = false;
      });
    }, 500); */
  }

  backOnError() {
    /*
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['vodafone-payment-test']);
    });*/
    this.isLoading = true;
    this.loadingMsg = "";
    this.log.info("---- Yönlendirme devam ediyor ----");
    this.log.info("isMobilApp: " + this.isMobilApp);
    if (this.isMobilApp) {
      this.log.info("forwardDelivery");
      forwardDelivery();
    } else {
      var returnUrl = this.route.snapshot.queryParamMap.get("returnUrl");
      if(returnUrl != null){
        window.open(encodeURI(returnUrl + "&payment_id=" + this.orderId + "&chatbotMsPaymentOk=false"), "_self");
      }else {
        this.log.info("forward: " + this.eshopCommitFailUrl);
        window.open(encodeURI(this.eshopCommitFailUrl), "_self");
      }
    }
  }

  expirationChanged(event) {
    //this.log.info("in: " + event);
  }

  validateInputs() {

    var validated = true;
    if (this.paymentProcessRequest.cardNumber && this.paymentProcessRequest.cardNumber.length == 16) {
      this.msgCardnumber = "";

      if (this.ccNumberRegex.test(this.paymentProcessRequest.cardNumber)) {
        this.msgCardnumber = "";
      } else {
        validated = false;
        this.msgCardnumber = "Lütfen geçerli bir kredi kartı girin.";
      }

    } else {
      validated = false;
      this.msgCardnumber = "Bu alanın doldurulması zorunludur.";
    }

    if (this.paymentProcessRequest.cardHolderName) {
      this.msgCardHolderName = "";
    } else {
      validated = false;
      this.msgCardHolderName = "Bu alanın doldurulması zorunludur.";
    }

    if (this.paymentProcessRequest.expiration && this.paymentProcessRequest.expiration.length == 4) {
      this.msgExpiration = "";

      if (+this.paymentProcessRequest.expiration.substr(0, 2) > 0 && +this.paymentProcessRequest.expiration.substr(0, 2) < 13
        && (+this.paymentProcessRequest.expiration.substr(2, 4) > +new Date().getFullYear().toString().substr(2, 4)

          || (+this.paymentProcessRequest.expiration.substr(2, 4) == +new Date().getFullYear().toString().substr(2, 4))
          && +this.paymentProcessRequest.expiration.substr(0, 2) > (+new Date().getMonth() + 1))

        && +this.paymentProcessRequest.expiration.substr(2, 4) < 50) {
        this.msgExpiration = "";
      } else {
        validated = false;
        this.msgExpiration = "Lütfen geçerli bir tarih girin.";
      }

    } else {
      validated = false;
      this.msgExpiration = "Bu alanın doldurulması zorunludur.";
    }

    if (this.paymentProcessRequest.cvcNumber && this.paymentProcessRequest.cvcNumber.length == 3) {
      this.msgCvcNumber = "";
    } else {
      validated = false;
      this.msgCvcNumber = "Bu alanın doldurulması zorunludur.";
    }

    //this.log.info(this.paymentProcessRequest);
    return validated;
  }

  normalizeSpace(text: string) {
    if (!text) {
      return "";
    }
    text = text.split("\"").join("");
    text = text.split("\/n").join("\n");
    return text;
  }

  paymentMock = {
    "data": {
      "payment": {
        "id": "ae3dcd38-52bd-4d00-84e7-b9b67ad0981d",
        "authorizationCode": "DCE-a038035b-990c-488a-8b83-f824262a8c09",
        "correlatorId": "88888888951",
        "description": "Vodafone Online Mağazadan aldığınız ürünlerin ön ödemesi olan ₺1860.00 kredi kartınızdan tahsil edilecektir. Kalan ₺165.00 x 12 ve ₺165.00 x 12 aylık ödemeler faturanızdan tahsil edilecektir.",
        "paymentDate": "2020-05-14T10:19:59.071Z",
        "status": "PAID",
        "statusDate": "2020-05-14T10:23:27.708Z",
        "amount": {
          "unit": "₺",
          "value": 1860.00
        },
        "channel": {
          "name": "web"
        },
        "payer": {
          "id": "5463002016"
        },
        "paymentItem": [
          {
            "amount": {
              "unit": "₺",
              "value": 165.00
            },
            "item": {
              "name": "\"Kalan tarifeye ek tutar /n 12 ay\"",
              "@referredType": "ScreenText"
            }
          },
          {
            "amount": {
              "unit": "₺",
              "value": 1860.00
            },
            "item": {
              "name": "\"Kredi kartı ile ödenecek tutar /n Ön ödeme\"",
              "@referredType": "ScreenText"
            }
          },
          {
            "amount": {},
            "item": {
              "name": "Kargo",
              "@referredType": "ScreenText"
            }
          },
          {
            "item": {
              "name": "Normal Teslimat",
              "@referredType": "ScreenText"
            }
          },
          {
            "amount": {},
            "item": {
              "name": "1860.00 TL Öde ve Siparişi Tamamla",
              "@referredType": "Button Label"
            }
          }
        ]
      },
      "paymentMapping": {
        "id": 1228,
        "version": 5,
        "createDateTime": "2020-05-14T13:20:02.34",
        "updateDateTime": "2020-05-14T13:23:29.854",
        "channel": "eshop",
        "orderId": "88888888951",
        "transactionId": "ae3dcd38-52bd-4d00-84e7-b9b67ad0981d",
        "amount": 1860.00,
        "remainAmount": 1860.00,
        "validDate": "2020-05-14T14:20:02.344",
        "isSuccess": true,
        "retryCount": 0,
        "splitPayment": false,
        "b2bPaymentType": "exact",
        "lastTransaction": {
          "id": 1229,
          "version": 2,
          "createDateTime": "2020-05-14T13:23:00.985",
          "updateDateTime": "2020-05-14T13:23:28.467",
          "isSuccess": true,
          "responseCode": "00",
          "responseMsg": "Approved",
          "returnUrl": "https://odeme.test.ouno.com.tr/payment?id=CzjuH2M69cx7BVGBkhaKhj9juQSqEUJJfrxzY9en9aBqN%2FZwOJVBvcSA7ku6b%2Fa6qvySeZqXUkNk%0D%0AEd3axbT5rA%3D%3D",
          "pgTranId": "20135NXbE11669",
          "paymentClientToken": "5IYVNGBR3I4HEWZRDXOK4NZ3MH2XV4PSZZCOOIGDUCQRVVEF"
        },
        "transactions": [
          {
            "id": 1229,
            "version": 2,
            "createDateTime": "2020-05-14T13:23:00.985",
            "updateDateTime": "2020-05-14T13:23:28.467",
            "isSuccess": true,
            "responseCode": "00",
            "responseMsg": "Approved",
            "returnUrl": "https://odeme.test.ouno.com.tr/payment?id=CzjuH2M69cx7BVGBkhaKhj9juQSqEUJJfrxzY9en9aBqN%2FZwOJVBvcSA7ku6b%2Fa6qvySeZqXUkNk%0D%0AEd3axbT5rA%3D%3D",
            "pgTranId": "20135NXbE11669",
            "paymentClientToken": "5IYVNGBR3I4HEWZRDXOK4NZ3MH2XV4PSZZCOOIGDUCQRVVEF"
          }
        ]
      }
    }
  };


}
