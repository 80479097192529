import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChannelRef } from 'src/app/model/channel-ref';
import { Payment } from 'src/app/model/payment';
import { EncriptionService } from 'src/app/service/encription-service';
import { OrderService } from 'src/app/service/order.service';
import { B2bPayment } from 'src/app/model/b2b-payment';
import { LogService } from 'src/app/service/log.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  isLoading = false;
  loadingMsg: string;

  constructor(private router: Router, private orderService: OrderService, 
    private encriptionService: EncriptionService, private log: LogService) { }

  ngOnInit(): void {
    this.log.info("--- TEST MODE ---");
  }

  redirectToPaymentEshop() {
    this.isLoading = true;
    this.loadingMsg = "Güvenli ödeme bilgileri sayfasına yönlendiriliyorsunuz.";

    // FAKE
    var payment = new Payment();
    payment.id = Date.now() + ""; // transaction id
    payment.correlatorId = Date.now() + ""; // order id // UUID uretilecek
    payment.channel = new ChannelRef();
    payment.channel.name = "eshop";
    this.orderService.savePaymentMapping(payment).subscribe((res: any) => {
      var encodedOrderId = this.encriptionService.encryptRSA(payment.correlatorId);
      this.router.navigate(['payment'], { queryParams: { id: encodeURI(encodedOrderId) } });
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      console.error(err);
    });
  }

  redirectToPaymentB2b() {
    this.isLoading = true;
    this.loadingMsg = "Güvenli ödeme bilgileri sayfasına yönlendiriliyorsunuz.";

    var orderId = Date.now() + "";
    var encodedOrderId = this.encriptionService.encryptRSA(orderId);
    this.router.navigate(['b2b-payment'], { queryParams: { id: encodeURI(encodedOrderId) } });
    //this.isLoading = false;
  }

}
