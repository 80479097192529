<div class="rectangle-first">
  <div
    class="container d-flex justify-content-between align-items-center rectangle-first"
  >
    <div>
      <div class="row align-items-center">
        <img
          src="assets/img/ouno.png"
          style="height: 36px; width: auto; margin-left: 16px;"
        />
        <div style="margin-left: 9px; margin-bottom: 0; font-size: 18px;"></div>
      </div>
    </div>
    <div></div>
  </div>
</div>

<div class="rectangle-body">
  <div class="container" *ngIf="success()">
    <div class="card-body-success text-center" style="position: relative;">
      <span
        style="
          color: white;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-20%, -70%);
        "
      >
        <img
          src="assets/img/info-circle.svg"
          style="width: 42px; height: 42px;"
        />
      </span>
      <div>
        Ödeme başarılı
      </div>
    </div>
  </div>

  <div class="container" *ngIf="!success()">
    <div class="card-body-error text-center" style="position: relative;">
      <span
        style="
          color: white;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-20%, -70%);
        "
      >
        <img
          src="assets/img/alert-triangle.svg"
          style="width: 42px; height: 42px;"
        />
      </span>
      <div>
        {{ message ? message : "Ödeme başarısız!" }}
      </div>
    </div>

    <div class="text-center" style="margin: 25px;">
      <!--<button class="btn btn-primary btn-retry" (click)="retry()">
        Tekrar Dene
      </button>-->
    </div>
  </div>
</div>
