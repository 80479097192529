import { ChannelType } from './channel-type';
import { KeyValue } from './key-value';

export class PaymentProcessRequest {


    pos: string;
    channel: ChannelType;
    orderId: string;
    cardHolderName: string;
    cardNumber: string;
    expiration: string; //transient
    expMonth: number;
    expYear: number;
    cvcNumber: string;
    installment: number; // optional
    amount: number; // optional
    returnUrl: string;
    params: KeyValue[];

}
