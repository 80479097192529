<div class="my-overlay" *ngIf="isLoading">
  <div>
    <div class="card-body" style="width: 400px;">
      <div style="text-align: center;">
        <img src="assets/img/loading.svg" />
      </div>
      <div *ngIf="message" style="text-align: center;">
        {{ message }}
      </div>
    </div>
  </div>
</div>
