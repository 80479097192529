import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentComponent } from './component/payment/payment.component';
import { PaymentResultComponent } from './component/payment-result/payment-result.component';
import { B2bPaymentComponent } from './component/b2b-payment/b2b-payment.component';
import { HomeComponent } from './component/home/home.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { CanActivateService } from './service/can-activate.service';
import { VodafonePaymentTestComponent } from './component/vodafone-payment-test/vodafone-payment-test.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [CanActivateService] },
  { path: 'b2b-payment', component: B2bPaymentComponent, canActivate: [CanActivateService] },
  { path: 'payment', component: PaymentComponent, canActivate: [CanActivateService] },
  { path: 'payment-result', component: PaymentResultComponent, canActivate: [CanActivateService] },
  { path: 'vodafone-payment-test', component: VodafonePaymentTestComponent, canActivate: [CanActivateService] },
  { path: '', redirectTo: 'home', pathMatch: 'full', canActivate: [CanActivateService] },
  {path: '404', component: PageNotFoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
