<app-loading [isLoading]="isLoading" [message]="loadingMsg"></app-loading>
<div class="rectangle-first">
  <div
    class="container d-flex justify-content-between align-items-center rectangle-first"
  >
    <div>
      <div class="row align-items-center">
        <img
          src="assets/img/ouno.png"
          style="height: 36px; width: auto; margin-left: 16px;"
        />
        <div style="margin-left: 9px; margin-bottom: 0; font-size: 18px;"></div>
      </div>
    </div>
    <div></div>
  </div>
</div>

<div class="rectangle-body" *ngIf="!isError && !isSuccessPayment" >
  <div class="container card-body">
    <h5>Kredi Kartı Ödeme Formu</h5>
    <hr />
    <div class="row">
      <div class="col-sm-6">
        <div>

          <!-- tutar bilgisi -->
          <div class="card-body-list" style="font-size: smaller;">

            <div class="row" style="margin-top: 20px;">
              <div class="col align-items-center">
                <span>Toplam Tutar</span>
              </div>
              <div class="col">
                <div>
                  <div class="input-group mb-3 nopadding">
                    <div 
                      class="form-control disabled"
                      style="background-color: #f9f9f9; color: #000000;"
                    >{{ totalAmount }}</div>

                    <div class="input-group-append">
                      <span class="input-group-text"
                      >₺</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col align-items-center">
                Ödenmiş Tutar
              </div>
              <div class="col">
                
                <div>
                  <div class="input-group mb-3 nopadding">
                    <div
                      class="form-control disabled" 
                      style="background-color: #f9f9f9; color: #000000;"
                    >{{ totalAmount - remainAmount }}</div>

                    <div class="input-group-append">
                      <span class="input-group-text"
                      >₺</span>
                      <span class="input-group-text" id="basic-addon2"
                      type="button" data-toggle="modal" data-target="#payTrxModal"
                      (click)="paymentQueryTransactions()"
                      >&#9776;</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col align-items-center">
                Ödenecek Tutar
              </div>
              <div class="col">
                <div>
                  <div class="input-group mb-3 nopadding">
                    <input
                      [(ngModel)]="amount"
                      type="text"
                      class="form-control"
                      id="amount"
                      name="amount"
                      required
                      maxlength="10"
                      title="Tutar "
                      pInputText
                      pKeyFilter="num"
                      [ngStyle]="msgAmount ? { borderColor: 'red' } : {}"
                      (focus)="msgAmount = ''"
                      (ngModelChange)="changedAmount()"
                    />

                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2">₺</span>
                    </div>
                  </div>

                  <p-message
                    style="font-size: smaller; color: red;"
                    severity="error"
                    [text]="msgAmount"
                    *ngIf="msgAmount"
                  ></p-message>
                </div>
              </div>
            </div>
          </div>


          
          
        </div>
      </div>

      <!-- kredi karti -->
      <div class="col-sm-6">
        <div >
          <div class="form-group">
            <label
              for="cardnumber"
              [ngStyle]="msgCardnumber ? { color: 'red' } : {}"
              >Kart Numarası</label
            >
            <p-inputMask
              id="cardnumber"
              name="cardnumber"
              styleClass="form-control"
              [(ngModel)]="paymentProcessRequest.cardNumber"
              (ngModelChange)="paymentQueryBin()"
              required
              mask="9999 9999 9999 9999"
              placeholder="____ ____ ____ ____"
              [autoClear]="false"
              unmask="true"
              [style]="msgCardnumber ? { borderColor: 'red' } : {}"
              (onFocus)="msgCardnumber = ''"
            ></p-inputMask>
            <p-message
              style="font-size: smaller; color: red;"
              severity="error"
              [text]="msgCardnumber"
              *ngIf="msgCardnumber"
            ></p-message>
          </div>
          <div class="form-group">
            <label
              for="cardHolderName"
              [ngStyle]="msgCardHolderName ? { color: 'red' } : {}"
              >Kart Üzerindeki İsim</label
            >
            <input
              [(ngModel)]="paymentProcessRequest.cardHolderName"
              type="text"
              class="form-control"
              id="cardHolderName"
              name="cardHolderName"
              required
              maxlength="30"
              title="Kart Üzerindeki İsim "
              pInputText
              [pKeyFilter]="ccNameRegex"
              [ngStyle]="msgCardHolderName ? { borderColor: 'red' } : {}"
              (focus)="msgCardHolderName = ''"
            />

            <p-message
              style="font-size: smaller; color: red;"
              severity="error"
              [text]="msgCardHolderName"
              *ngIf="msgCardHolderName"
            ></p-message>
          </div>
          <div class="row">
            <div class="col-sm">
              <div class="form-group">
                <label
                  for="expiration"
                  [ngStyle]="msgExpiration ? { color: 'red' } : {}"
                  >Son Kullanma Tarihi</label
                >
                <p-inputMask
                  id="expiration"
                  name="expiration"
                  styleClass="form-control"
                  [(ngModel)]="paymentProcessRequest.expiration"
                  required
                  mask="99/99"
                  placeholder="__/__"
                  [autoClear]="false"
                  (ngModelChange)="expirationChanged($event)"
                  unmask="true"
                  [style]="msgExpiration ? { borderColor: 'red' } : {}"
                  (onFocus)="msgExpiration = ''"
                ></p-inputMask>
                <p-message
                  style="font-size: smaller; color: red;"
                  severity="error"
                  [text]="msgExpiration"
                  *ngIf="msgExpiration"
                ></p-message>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label
                  for="cvcNumber"
                  [ngStyle]="msgCvcNumber ? { color: 'red' } : {}"
                  >Güvenlik Kodu</label
                >

                <div style="position: relative;">
                  <p-inputMask
                    id="cvcNumber"
                    name="cvcNumber"
                    styleClass="form-control"
                    [(ngModel)]="paymentProcessRequest.cvcNumber"
                    required
                    mask="999"
                    placeholder="___"
                    [autoClear]="false"
                    unmask="true"
                    [style]="msgCvcNumber ? { borderColor: 'red' } : {}"
                    (onFocus)="msgCvcNumber = ''"
                  ></p-inputMask>
                  <span
                    style="
                      position: absolute;
                      right: 0;
                      top: 1px;
                      padding: 3px;
                      padding-right: 10px;
                    "
                  >
                    <img src="assets/img/group-8.svg" />
                  </span>
                  <p-message
                    style="font-size: smaller; color: red;"
                    severity="error"
                    [text]="msgCvcNumber"
                    *ngIf="msgCvcNumber"
                  ></p-message>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- odeme secenekleri -->
    <div class="row">
      <div [ngClass]="paymentSystems ? 'col-sm-12' : 'col-sm-12 disabled'" >
        <div >

          <!-- pos -->
          <h5>Taksit seçenekleri</h5>
          <hr />

          <h6>Tek Çekim</h6>
          <table class="table table-bordered table-sm" style="width: auto; margin-top:20px; font-size: smaller;" 
          *ngIf="paymentSystems">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">
                  <span>
                    <img src="assets/img/bankalar/VISAMASTER.jpg"
                    style="
                      height: 32px;
                      width: auto;" />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Tek Çekim</td>
                <td>
                  <ng-container *ngIf="defPaymentSystem?.installmentList && defPaymentSystem?.installmentList[0]">
                    <div class="col text-center nowrap">
                      <span><input class="form-check-input" type="radio" name="selectInstallment" checked 
                        (change)="selectPaymentSystem(defPaymentSystem, '1')" />
                      </span>% {{defPaymentSystem?.installmentList[0]?.commission?.rate}}</div>
                    <div class="col text-center nowrap">₺ {{defPaymentSystem?.installmentList[0]?.commission?.finalAmount}}</div>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>


          <ng-container  *ngIf="paymentSystems && paymentSystems[1]">
            <h6>Taksit</h6>
            <table class="table table-bordered table-sm" style="width: auto; margin-top:20px; font-size: smaller;">
              <thead>
                <th></th>
                <ng-container *ngFor="let paymentSystem of paymentSystems">
                  <th *ngIf="paymentSystem?.installmentList && paymentSystem?.installmentList[1]">
                    <span>
                      <img [src]="imgPaymentSystem(paymentSystem)"
                      style="
                        height: 32px;
                        width: auto;" />
                    </span>
                  </th>
                </ng-container>
              </thead>
              <tbody>
                <tr *ngFor="let rowInst of maxInstallments">
                  <td>{{rowInst}}</td>
                  <ng-container *ngFor="let paymentSystem of paymentSystems">
                    <td *ngIf="paymentSystem?.installmentList && paymentSystem?.installmentList[1]">
                      <ng-container *ngFor="let installment of paymentSystem?.installmentList">
                        <div *ngIf="rowInst == installment.count">
                          <div class="col text-center nowrap"><span>
                            <input class="form-check-input" type="radio" name="selectInstallment"
                            (change)="selectPaymentSystem(paymentSystem, rowInst)"
                            /></span>% {{installment?.commission?.rate}}</div>
                          <div class="col text-center nowrap">₺ {{installment?.commission?.finalAmount}}</div>
                        </div>
                      </ng-container>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </ng-container>
        

        <div class="form-group" style="margin-top: 1rem;">
          <img
            src="assets/img/ic-security-red.svg"
            style="
              height: 32px;
              width: 32px;
              margin-left: 16px;
              display: inline;
            "
          />
          <div style="display: inline;">
            <bold>Ödemeniz 3D Secure ile güvendedir.</bold>
          </div>
        </div>

        <div class="button-section" style="margin-top: 10px;">
          <div>
            <button
              type="button"
              (click)="processPayment()"
              [disabled]="isLoading || !selectedPaymentSystem"
              class="btn btn-primary"
              style="margin-bottom: 10px; min-width: 300px;"
            >
                Ödeme Yap  
            </button>
          </div>
        </div>

      </div>

      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="!isLoading && !isError && isSuccessPayment">
  <div class="card-body-success text-center" style="position: relative;">
    <span
      style="
        color: white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-20%, -70%);
      "
    >
      <img
        src="assets/img/info-circle.svg"
        style="width: 42px; height: 42px;"
      />
    </span>
    <div>
      Ödeme başarılı
    </div>
  </div>

  <div class="row" class="text-center" style="margin: 25px;">
    <button class="btn btn-primary btn-back" (click)="backOnError()">
      Geri Dön
    </button>
  </div>
</div>

<div class="container" *ngIf="!isLoading && isError">
  <div class="card-body-error text-center" style="position: relative;">
    <span
      style="
        color: white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-20%, -70%);
      "
    >
      <img
        src="assets/img/alert-triangle.svg"
        style="width: 42px; height: 42px;"
      />
    </span>
    <div>
      {{ errorMsg }}
    </div>
  </div>

  <div>
    <div class="row" class="text-center" style="margin: 25px;">
      <button class="btn btn-primary btn-retry" (click)="retryOnError()">
        Tekrar Dene
      </button>
    </div>
    <div class="row" class="text-center" style="margin: 25px;">
      <button class="btn btn-primary btn-back" (click)="backOnError()">
        Geri Dön
      </button>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="payTrxModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ödeme Geçmişi</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <table class="table table-bordered table-striped" style="font-size: smaller;" *ngIf="paymentMapping?.transactions">
            <thead>
              <tr>
                <th scope="col">Tarih</th>
                <th scope="col">Tutar</th>
                <th scope="col">Kredi Kartı</th>
                <th scope="col">Kart Sahibi</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let trx of paymentMapping?.transactions">
                <ng-container *ngIf="trx.isSuccess">
                  <tr >
                    <td>{{trx.createDateTime | date:'yyyy-MM-dd hh:mm:ss'}} </td>
                    <td>₺{{trx.amount}}</td>
                    <td>{{trx.extra?.bin}}******{{trx.extra?.panLast4}}</td>
                    <td>{{trx.extra?.cardOwnerMasked}}</td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
          <p *ngIf="!paymentMapping?.transactions">Ödeme geçmişi bulunamadı.</p>
      </div>
    </div>
  </div>
</div>