import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { B2bPayment } from 'src/app/model/b2b-payment';
import { B2bPaymentType } from 'src/app/model/b2b-payment-type';
import { ChannelType } from 'src/app/model/channel-type';
import { PaymentMapping } from 'src/app/model/payment-mapping';
import { PaymentOrderIdRequest } from 'src/app/model/payment-order-id-request';
import { PaymentProcessRequest } from 'src/app/model/payment-process-request';
import { PaymentProcessResponse } from 'src/app/model/payment-process-response';
import { PaymentSetsRequest } from 'src/app/model/payment-sets-request';
import { PaymentSystemsResponse } from 'src/app/model/payment-systems-response';
import { PaymentWrapper } from 'src/app/model/payment-wrapper';
import { ResponseData } from 'src/app/model/response-data';
import { EncriptionService } from 'src/app/service/encription-service';
import { OrderService } from 'src/app/service/order.service';
import { PaymentService } from 'src/app/service/payment.service';
import { PaymentSystem } from 'src/app/model/payment-system';
import { PaymentQueryRequest } from 'src/app/model/payment-query-request';
import { PaymentBinResponse } from 'src/app/model/payment-bin-response';
import { LogService } from 'src/app/service/log.service';
import { PaymentQueryTransaction } from 'src/app/model/payment-query-transaction';

declare function setInputFilter(value: any, func: any): any;

@Component({
  selector: 'app-b2b-payment',
  templateUrl: './b2b-payment.component.html',
  styleUrls: ['./b2b-payment.component.css']
})
export class B2bPaymentComponent implements OnInit {

  orderIdRequest = new PaymentOrderIdRequest();
  private orderId: string;
  paymentProcessRequest = new PaymentProcessRequest();
  isLoading = false;
  loadingMsg: string;
  paymentWrapper: PaymentWrapper;
  payment: B2bPayment;
  paymentMapping: PaymentMapping;
  ccNameRegex: RegExp = /^[a-zA-Z ğüşöçİĞÜŞÖÇ]*$/i;
  ccNumberRegex: RegExp = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;


  // error
  isError: boolean;
  errorMsg: string;

  // paymentSystems
  //paymentSystems: PaymentSystem[];
  paymentSetsRequest = new PaymentSetsRequest();
  //paymentSystemsResponse = new PaymentSystemsResponse();
  //installments = new Set(["1"]);
  paymentSystems: PaymentSystem[];
  defPaymentSystem = new PaymentSystem();
  selectedPaymentSystem: PaymentSystem;
  maxInstallments = new Set();
  selectedInstallment = "1";
  lastBin: string;
  mPaymentBin: PaymentBinResponse;

  amount: number;
  totalAmount: number;
  remainAmount: number;

  isSuccessPayment: boolean;
  //showCardBody: boolean;

  // err msgs
  msgCardHolderName: string;
  msgCardnumber: string;
  msgExpiration: string;
  msgCvcNumber: string;
  msgAmount: string;
  msgPaySystem: string;

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute,
    private paymentService: PaymentService, private orderService: OrderService,
    private encriptionService: EncriptionService, private log: LogService) {

  }

  ngOnInit(): void {

    var encodedOrderId = this.route.snapshot.queryParamMap.get("id");
    //this.log.info(decodeURI(encodedOrderId));
    this.orderId = this.encriptionService.decryptRSA(decodeURI(encodedOrderId));
    //this.log.info(this.orderId);
    if (!this.orderId) {
      this.isError = true;
      this.errorMsg = "Sipariş bilgisi bulunamadı!";
      this.log.error("order id not found!");
    } else {
      this.getPaymentDetails();
    }
  }


  getPaymentDetails() {
    this.isLoading = true;
    this.loadingMsg = "Güvenli ödeme sayfası yükleniyor...";

    this.orderIdRequest.orderId = this.orderId;
    this.orderIdRequest.channel = "b2b";
    this.orderIdRequest.paymentType = B2bPaymentType.split; // TODO

    this.payment = new B2bPayment();

    return this.orderService.getB2bPaymentByOrderId(this.orderIdRequest)
      .subscribe((res: any) => {
        this.log.info(res);

        this.paymentWrapper = res.data;
        this.payment = this.paymentWrapper.b2bPayment;
        this.paymentMapping = this.paymentWrapper.paymentMapping;

        this.totalAmount = this.paymentMapping.amount;

        this.remainAmount = this.paymentMapping.remainAmount;
        this.amount = this.remainAmount;

        // for app
        //this.isMobilApp = this.payment.channel.name === 'app';
        //this.log.info("valid to : " + new Date(this.paymentMapping.validDate));

        /*
        if (new Date(this.paymentMapping.validDate) < new Date()) {
          this.isError = true;
          this.errorMsg = "Sayfa zaman aşımına uğradı.";
          this.isLoading = false;
          return;
        }*/

        if (this.paymentMapping.isSuccess == true) {
          this.isLoading = false;
          this.loadingMsg = "";
          this.log.info("---- Ödeme başarılı.----");
          this.isSuccessPayment = true;
          return;
        }

        // for error
        if (this.paymentMapping.isSuccess == false) {
          this.isError = true;
          this.errorMsg = this.paymentMapping.errorMsg;
          this.isLoading = false;
          this.loadingMsg = "";
          return;
        }

        this.getPaymentSets();

        if (!this.paymentProcessRequest) {
          this.paymentProcessRequest = new PaymentProcessRequest();
        }
        
        this.paymentProcessRequest.channel = ChannelType.b2b;
        this.paymentProcessRequest.orderId = this.payment.orderId;
        /*this.paymentProcessRequest.cardHolderName = "A B";
        this.paymentProcessRequest.cardNumber = "4355084355084358";
        this.paymentProcessRequest.cvcNumber = "000";
        this.paymentProcessRequest.expMonth = 12;
        this.paymentProcessRequest.expYear = 2030
        this.paymentProcessRequest.expiration = 1230 + "";*/

        //this.getPaymentSets();
        //this.isLoading = false;
        //this.showCardBody = true;
      }, err => {
        this.log.error(err);
        this.isLoading = false;
        this.loadingMsg = "";
      });
  }

  paymentQueryBin() {
    var cardNumber = this.paymentProcessRequest.cardNumber;
    if (cardNumber && cardNumber.length == 16
      && cardNumber.substr(0, 6) != this.lastBin) {
      this.lastBin = cardNumber.substr(0, 6);
      var paymentQuery = new PaymentQueryRequest();
      paymentQuery.channel = ChannelType.b2b;
      paymentQuery.params = [{
        key: "ACTION",
        value: "QUERYBIN"
      },
      {
        key: "BIN",
        value: this.lastBin
      },
      {
        key: "DEALERTYPENAME",
        value: "OUNODEALERTYPE"
      }];

      this.isLoading = true;
      this.loadingMsg = "";
      this.mPaymentBin = null;
      this.msgCardnumber = "";
      this.paymentService.paymentQuery(paymentQuery)
        .subscribe((res: ResponseData) => {
          this.isLoading = false;
          this.loadingMsg = "";

          if (res?.data?.errorCode) {
            this.log.error(this.lastBin);
            this.log.error(res);
            this.msgCardnumber = "Lütfen geçerli bir kredi kartı girin.";
          }

          if (res && res.data && res.data.paymentSystem) {
            this.mPaymentBin = res.data;
            //this.log.info("mPaymentBin:");
            //this.log.info(this.mPaymentBin)
            //this.log.info("all:");
            //this.log.info(this.paymentSystems);

            this.getPaymentSets();
          }

        }, err => {
          this.log.error(err);
          this.isLoading = false;
          this.loadingMsg = "";
        });
    }
  }

  getPaymentSets() {

    this.loadingMsg = "Taksit seçenekleri yükleniyor...";
    this.isLoading = true;

    this.paymentSetsRequest.channel = ChannelType.b2b;
    this.paymentSetsRequest.amount = this.amount;

    this.paymentSystems = [];
    this.maxInstallments = new Set();
    this.paymentProcessRequest.channel = ChannelType.b2b;
    this.paymentProcessRequest.orderId = this.orderId;
    this.paymentService.getPaymentSets(this.paymentSetsRequest)
      .subscribe((res: ResponseData) => {
        this.log.info(res);
        if (res && res.data) {
          var paymentSystemsResponse: PaymentSystemsResponse = res.data;
          this.defPaymentSystem = paymentSystemsResponse.defaultPaymentSystemWithInstallments;

          this.paymentSystems.push(this.defPaymentSystem);
          for (var paymentSystem of paymentSystemsResponse.paymentSystems) {
            this.paymentSystems.push(paymentSystem);
          }

          // test
          const keys = new Set();
          this.paymentSystems = this.paymentSystems.filter(item => !keys.has(JSON.stringify(item)) ? keys.add(JSON.stringify(item)) : false);
          this.paymentSystems = this.paymentSystems.filter(item => !item.name.includes('komisyonlu'));


          if (this.mPaymentBin) {
            this.paymentSystems = this.paymentSystems.filter(item =>
              (item.type == this.defPaymentSystem.type || item.type == this.mPaymentBin?.paymentSystem?.type));
            this.log.info("filtered:")
            this.log.info(this.paymentSystems);
          }

          for (var paymentSystem of this.paymentSystems) {
            for (var installment of paymentSystem.installmentList) {
              if (installment.count != '1') {
                this.maxInstallments.add(installment.count);
              }
            }
          }
          this.defPaymentSystem = this.paymentSystems[0];
          this.selectedPaymentSystem = this.defPaymentSystem;
          this.selectedInstallment = "1";
        }
        this.isLoading = false;
        //this.showCardBody = true;
      }, err => {
        this.log.error(err.error);
        this.isLoading = false;
        this.loadingMsg = "";
      });
  }

  processPayment() {
    this.isLoading = true;
    this.loadingMsg = "";

    if (!this.validateInputs()) {
      //this.messageService.add({severity:'error', summary:'Lütfen tüm alanları doğru girin', detail:''});
      //setTimeout(() => {
      this.isLoading = false;
      this.loadingMsg = "";
      //}, 500);
      return;
    }

    this.paymentProcessRequest.pos = this.selectedPaymentSystem.name; // prod: "bilisim_halkbank_komisyonsuz";
    this.paymentProcessRequest.installment = +this.selectedInstallment;
    this.paymentProcessRequest.amount = this.amount;

    if (this.paymentProcessRequest.pos.includes('Kobi')
      || this.paymentProcessRequest.pos.includes('kobi')) {
      this.paymentProcessRequest.params = [{
        key: "EXTRA",
        value: "{\"HalkbankKobiKartDestek\":\"YES\", \"HALKKOBIKART.OZELTAKSITLIISLEM\":\"E\", \"HALKKOBIKART.ODEMESIKLIGI\":\"1\"}"
      }, {
        key: "DEALERTYPENAME",
        value: "OUNODEALERTYPE"
      }];
    }

    if (this.paymentProcessRequest.cardNumber) {
      this.paymentProcessRequest.cardNumber = this.paymentProcessRequest.cardNumber.split(" ").join("");
    }
    if (this.paymentProcessRequest.expiration) {
      this.paymentProcessRequest.expiration = this.paymentProcessRequest.expiration.replace("/", "");
      this.paymentProcessRequest.expMonth = +this.paymentProcessRequest.expiration.substr(0, 2);
      this.paymentProcessRequest.expYear = +("20" + this.paymentProcessRequest.expiration.substr(2, 4));
    }

    this.log.info("url: " + location.href);
    this.paymentProcessRequest.returnUrl = location.href;

    //this.log.info(this.paymentProcessRequest);
    this.paymentService.processPayment(this.paymentProcessRequest)
      .subscribe((res: ResponseData) => {

        this.log.info(res);

        var paymentProcessResponse: PaymentProcessResponse = res.data;

        if (paymentProcessResponse.threeDHtmlBody) {
          if (paymentProcessResponse.threeDHtmlBody.includes("ErrorCode")
            || paymentProcessResponse.threeDHtmlBody.includes("ErrorMsg")) {
            this.isError = true;
            this.errorMsg = "Ödeme işlemi sırasında hata oluştu. Lütfen tekrar deneyin.";
            this.isLoading = false;
            this.loadingMsg = "";
          } else {
            document.open();
            document.write(paymentProcessResponse.threeDHtmlBody);
            document.close();
          }
        } else if (paymentProcessResponse.threeDUrl) {
          window.open(encodeURI(paymentProcessResponse.threeDUrl), "_self");
        } else {
          throw new Error("3D url or body not found!");
        }

      }, err => {
        this.log.error(err);
        this.isLoading = false;
        this.loadingMsg = "";
      });
  }

  retryOnError() {
    this.isLoading = true;
    this.loadingMsg = "";
    setTimeout(() => {
      this.paymentService.clearBeforeRetry(this.orderIdRequest).subscribe(res => {
        this.log.info(res);
        this.log.info("---- Yönlendirme devam ediyor ----");
        location.reload();
        //this.isLoading = false;
      }, err => {
        this.log.error(err);
        this.isLoading = false;
        this.loadingMsg = "";
      });
    }, 500);
  }

  backOnError() {
    this.isLoading = true;
    this.loadingMsg = "";
    this.log.info("---- Yönlendirme devam ediyor ----");
    this.log.info("B2B Home");
    this.router.navigate(['']);
  }

  expirationChanged(event) {
    //this.log.info("in: " + event);
  }

  validateInputs() {

    var validated = true;
    if (this.paymentProcessRequest.cardNumber && this.paymentProcessRequest.cardNumber.length == 16) {
      this.msgCardnumber = "";

      if (this.ccNumberRegex.test(this.paymentProcessRequest.cardNumber)) {
        this.msgCardnumber = "";
      } else {
        validated = false;
        this.msgCardnumber = "Lütfen geçerli bir kredi kartı girin.";
      }

    } else {
      validated = false;
      this.msgCardnumber = "Bu alanın doldurulması zorunludur.";
    }

    if (this.paymentProcessRequest.cardHolderName) {
      this.msgCardHolderName = "";
    } else {
      validated = false;
      this.msgCardHolderName = "Bu alanın doldurulması zorunludur.";
    }

    if (this.paymentProcessRequest.expiration && this.paymentProcessRequest.expiration.length == 4) {
      this.msgExpiration = "";

      if (+this.paymentProcessRequest.expiration.substr(0, 2) > 0 && +this.paymentProcessRequest.expiration.substr(0, 2) < 13
        && (+this.paymentProcessRequest.expiration.substr(2, 4) > +new Date().getFullYear().toString().substr(2, 4)

          || (+this.paymentProcessRequest.expiration.substr(2, 4) == +new Date().getFullYear().toString().substr(2, 4))
          && +this.paymentProcessRequest.expiration.substr(0, 2) > (+new Date().getMonth() + 1))

        && +this.paymentProcessRequest.expiration.substr(2, 4) < 50) {
        this.msgExpiration = "";
      } else {
        validated = false;
        this.msgExpiration = "Lütfen geçerli bir tarih girin.";
      }

    } else {
      validated = false;
      this.msgExpiration = "Bu alanın doldurulması zorunludur.";
    }

    if (this.paymentProcessRequest.cvcNumber && this.paymentProcessRequest.cvcNumber.length == 3) {
      this.msgCvcNumber = "";
    } else {
      validated = false;
      this.msgCvcNumber = "Bu alanın doldurulması zorunludur.";
    }

    if (this.amount && this.amount > 0) {
      this.msgAmount = "";
      if (this.amount <= this.remainAmount) {
        this.msgAmount = "";
      } else {
        validated = false;
        this.msgAmount = "Lütfen geçerli bir tutar girin.";
      }
    } else {
      validated = false;
      this.msgAmount = "Bu alanın doldurulması zorunludur.";
    }

    if (this.selectedPaymentSystem && this.selectedInstallment) {
      this.msgPaySystem = "";
    } else {
      validated = false;
      this.msgPaySystem = "Bu alanın doldurulması zorunludur.";
    }

    //this.log.info(this.paymentProcessRequest);
    return validated;
  }

  selectPaymentSystem(paymentSystem: PaymentSystem, installment: string) {
    this.selectedPaymentSystem = paymentSystem
    this.selectedInstallment = installment;
    //this.log.info("selectedPaymentSystem:");
    //this.log.info(this.selectedPaymentSystem);
    //this.log.info(this.selectedInstallment);
  }

  imgPaymentSystem(paymentSystem: PaymentSystem) {
    if (paymentSystem) {
      return "assets/img/bankalar/" + paymentSystem.type + ".jpg";
    } else {
      return null;
    }
  }

  changedAmountTimer;
  changedAmount() {
    this.log.info("changedAmount");
    clearTimeout(this.changedAmountTimer);
    this.changedAmountTimer = setTimeout(() => {
      this.log.info("changedAmount- call");
      this.getPaymentSets();
    }, 500);
  }

  paymentQueryTransactions() {
    if (this.paymentMapping && this.paymentMapping.transactions) {
      for (var paymentTransaction of this.paymentMapping?.transactions) {
        if (paymentTransaction?.pgTranId) {
          var paymentQuery = new PaymentQueryRequest();
          paymentQuery.channel = ChannelType.b2b;
          paymentQuery.params = [{
            key: "ACTION",
            value: "QUERYTRANSACTION"
          }, {
            key: "PGTRANID",
            value: paymentTransaction.pgTranId
          }];

          this.isLoading = true;
          this.loadingMsg = "";
          this.paymentService.paymentQuery(paymentQuery)
            .subscribe((res: ResponseData) => {
              var queryTransaction: PaymentQueryTransaction = res.data;
              if (queryTransaction.transactionList && queryTransaction.transactionList[0]) {
                paymentTransaction.extra = queryTransaction.transactionList[0];
              }
              this.isLoading = false;
              this.loadingMsg = "";
            }, err => {
              console.error(err);
              this.isLoading = false;
              this.loadingMsg = "";
            });
        }
      }
    }
  }

}
