import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor() { }

  info(message: any) {
    if (!environment.production) {
      const date = new Date().toISOString();
      console.log("info: " + date);
      console.log(message);
    }
  }

  error(message: any) {
    if (!environment.production) {
      const date = new Date().toISOString();
      console.error("error: " + date);
      console.error(message);
    }
  }

}
