import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../service/authentication.service';
import { LogService } from '../service/log.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {


  private token: string;

  constructor(private authService: AuthenticationService, private log: LogService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.startsWith(this.authService.authUrl)) {
      return next.handle(req);
    }

    var username, password;
    if (environment.production) {
      username = "vodafone";
      password = "8dwqqau2fygf";
    } else {
      username = "vodafonetest";
      password = "5454";
    }

    return this.authService.login(username, password).pipe(
      tap((res: any) => this.token = res.data.token),
      switchMap((res: any) => {
        const newReq = req.clone({
          setHeaders: {
            Authorization: 'bearer ' + res.data.token
          }
        });
        this.log.info(newReq);
        return next.handle(newReq);
      })
    );
  }

}
