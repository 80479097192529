import {ChannelType} from "./channel-type";

export class MasterPassPurchaseRequest {

  channel: ChannelType;
  orderId: string;
  returnUrl: string;
  masterPassReferenceNo: string;

}
