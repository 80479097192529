import { Money } from './money';
import { ChannelRef } from './channel-ref';
import { PaymentItem } from './payment-item';
import { PayerRef } from "./payer-ref";

export class Payment {

    id: string; // transaction id
    correlatorId: string; // order id

    description: string;
    name: string;
    amount: Money;
    channel: ChannelRef;
    payer: PayerRef;

    paymentItem: PaymentItem[];

}
